import React from 'react';
import { Space } from './locations/space';

class Player {

  position= {
    x: 0,
    y: 0,
    frame: 0,
    facing: 'right',
    moving: false,
    startTime: (new Date()).getTime(),
  };

  username= null;

  size= {
    width: 78,
    height: 119
  };

  speed= 10;

  keyControls= {
    leftPressed: false,
    rightPressed: false,
    upPressed: false,
    downPressed: false
  };

  connected= false;
  drawFrameInterval: any;
  positionInterval: any;
  space: Space;
  otherPlayersInterval: any;

  constructor(space:Space){
    this.space = space;
  }

  init() {
    console.log('yo',this)

    this.username = 'Guest' + Math.random() * 100000;

    try {
      if ('username' in localStorage && localStorage.username !== '') {
        this.username = localStorage.username.replace(/\ /g, '_').replace(/[^a-zA-Z 0-9 ]+/g,'');
      } else {
        localStorage.username = this.username;
      }
    } catch(er) {
      console.log(er)
    }

    $('.newName').val(this.username);

    $(document).bind('keydown', (e) =>{
      if (e.keyCode == 39 || e.keyCode == 68)
        this.keyControls.rightPressed = true;
      if (e.keyCode == 37 || e.keyCode == 65)
        this.keyControls.leftPressed = true;
      if (e.keyCode == 38 || e.keyCode == 87)
        this.keyControls.upPressed = true;
      if (e.keyCode == 40 || e.keyCode == 83)
        this.keyControls.downPressed = true;
    });

    $(document).bind('keyup', (e) =>{
      if (e.keyCode == 39 || e.keyCode == 68)
        this.keyControls.rightPressed = false;
      if (e.keyCode == 37 || e.keyCode == 65)
        this.keyControls.leftPressed = false;
      if (e.keyCode == 38 || e.keyCode == 87)
        this.keyControls.upPressed = false;
      if (e.keyCode == 40 || e.keyCode == 83)
        this.keyControls.downPressed = false;
    });

    this.drawFrameInterval = setInterval(() =>{
      this.space.drawFrame(this);
    }, 10);

    this.positionInterval = setInterval(() =>{

      this.position.frame++;

      if (this.position.frame > 11) this.position.frame = 0;

      if (this.keyControls.rightPressed || this.keyControls.leftPressed || this.keyControls.upPressed || this.keyControls.downPressed) {
        this.position.moving = true;

        if (this.keyControls.rightPressed)
          this.position.x += this.speed;

        if (this.keyControls.leftPressed)
          this.position.x -= this.speed;

        if (this.keyControls.upPressed)
          this.position.y += this.speed;

        if (this.keyControls.downPressed)
          this.position.y -= this.speed;

        if (this.position.x > this.space.stageWidth - (this.size.width/2))
          this.position.x = this.space.stageWidth - (this.size.width/2);

        if (this.position.x < 0)
          this.position.x = 0;

        if (this.position.y > this.space.stageHeight - (this.size.height/2))
          this.position.y = this.space.stageHeight - (this.size.height/2);

        if (this.position.y < this.size.width/2)
          this.position.y = this.size.width/2;

        //  work out if the glitch needs to turn left or right
        if (this.keyControls.rightPressed && !this.keyControls.leftPressed) {
          //  if we are turning then we need to remove the class
          if (this.position.facing == 'left') {
            $('.player_frame').removeClass('facingLeft');
            this.position.facing = 'right';
          }
        }

        if (!this.keyControls.rightPressed && this.keyControls.leftPressed) {
          //  if we are turning then we need to add the class
          if (this.position.facing == 'right') {
            $('.player_frame').addClass('facingLeft');
            this.position.facing = 'left';
          }
        }

      } else {
        this.position.moving = false;
      }

      //  if the player is moving update the frame
      if (this.position.moving) {
        var frame:any = Math.floor(((new Date()).getTime() - this.position.startTime) / 50) % 11;
        $('.player').css({
          'background-position': -(frame * this.size.width) + 'px 0'
        });
      } else {
        $('.player').css({
          'background-position': -(14 * this.size.width) + 'px 0'
        });
      }

      $('.player_holder').css('z-index', 9999 - this.position.y);

    }, 40);

  }


}
export {Player}
