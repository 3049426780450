import { GlitchDeco, GlitchLayer, GlitchGame, GlitchGradient, GlitchFilters, GlitchPlatformLine } from "./space";
import {sortBy} from 'lodash';
import {fabric} from 'fabric';
import { LayerAttributes, SpaceAttributes } from "models/space";
import { CSSProperties } from "react";

const createSpaceFromLocation:(layers:LayerAttributes[], space:Partial<SpaceAttributes> )=>SpaceAttributes = (layers, space) => {

  const middleground = layers.find((l) => l.id == 'middleground');

  if(!middleground)
    throw "No middleground"

  space = space || {
    preview_url: null,
    start_point: {x:100,y: middleground.total_height - 100},
    uuid: 'asdf',
    name: 'Hola',
    description: 'asereje',
    metadata:{},
    canEdit: true,
    owner: 'asdf',
    npc_placements: [],
  };

  return {
    ...space,
    layers: layers,
    tiles: [{name: '',
      x: 0, 
      y: 0, 
      events: {},
      space_id: space.uuid,
      id: 'asdf',
      public_filename: middleground.image_url,
    }],
    width: 1,
    height: 1,
    total_width: middleground.total_width,
    total_height: middleground.total_height,
    tile_width: middleground.total_width,
    tile_height: middleground.total_height,
  } as SpaceAttributes
}

const parseGlitchLocation:(game:GlitchGame, layer_images_map?:{[name:string]:string})=>Promise<LayerAttributes[]> = (game, layer_images_map) => {
  return Promise.all(Object.keys(game.dynamic.layers).map((id) => createCompatibleLayer(id, game.dynamic.layers[id], layer_images_map?.[id])))
}

const parseGradients = (gradient:GlitchGradient) => {

  let topGradient = null;
  let bottomGradient = null;

  if (parseInt(gradient.top) >> 16 === 0 && parseInt(gradient.top) >> 8 === 0 &&
    (gradient.top.length == 6 ||
      gradient.top.length == 4 ||
      gradient.top.length == 1)) {

    topGradient = gradient.top;
    while (topGradient.length < 6) {
      topGradient = '0' + topGradient;
    }
    topGradient = '#' + topGradient;

    bottomGradient = gradient.bottom;
    while (bottomGradient.length < 6) {
      bottomGradient = '0' + bottomGradient;
    }
    bottomGradient = '#' + bottomGradient;

  } else {
    var r = (parseInt(gradient.top) >> 16) & 0xff;
    var g = (parseInt(gradient.top) >> 8) & 0xff;
    var b = parseInt(gradient.top) & 0xff;
    topGradient = 'rgb(' + r + ',' + g + ',' + b + ')';

    r = (parseInt(gradient.bottom) >> 16) & 0xff;
    g = (parseInt(gradient.bottom) >> 8) & 0xff;
    b = parseInt(gradient.bottom) & 0xff;
    bottomGradient = 'rgb(' + r + ',' + g + ',' + b + ')';
  }

  return [topGradient, bottomGradient]

}

const parseFilters = (filters:GlitchFilters) => {
  let tintColor = null;
  let tintAmount = null;

  return Object.keys(filters).reduce((acc:string[],k:string) => {
    let filterValue = filters[k];

    switch(k){
      case 'brightness':
        acc.push(`brightness(${1-(filterValue/-100)})`);
        break;
      case 'contrast':
        acc.push(`contrast(${1-(filterValue/-100)})`);
        break;

      case 'saturation':
        acc.push(`saturation(${1-(filterValue/-100)})`);
        break;

      case 'tintColor':
        tintColor = filterValue;
        break;
      case 'tintAmount':
        tintAmount = filterValue;
        break;
    }
    return acc
  }, [])

}

const stageCSS = (gameObject:GlitchGame) => {

  const [topGradient, bottomGradient] = parseGradients(gameObject.gradient);

  return {
    backgroundImage: 'linear-gradient(to bottom, ' + topGradient + ' 0%, ' + bottomGradient + ' 100%)',
    position: 'relative',
    width: gameObject.dynamic.r - gameObject.dynamic.l,
    height: gameObject.dynamic.b - gameObject.dynamic.t,
    left: 304,
  } as CSSProperties;
}

const decoTransform = (deco:GlitchDeco) => {
  return {
    transform: [deco.r ? "rotate("+deco.r+"deg)" : null, deco.h_flip ? "scaleX(-1)" : null].filter(Boolean).join(' '),
    transformOrigin: '50% 50% 0',
  }
}
const wallCSS = (deco:GlitchDeco, offset:{x:number,y:number}) => {
  return {
    ...decoCSS(deco,offset),
    width: 1,
    top: offset.y + (deco.y),
  } as CSSProperties;
}


const platformLineCSS = (platformLine:GlitchPlatformLine, offset) => {
  const start_pt = platformLine.endpoints[0]
  const end_pt = platformLine.endpoints[1]
  const length = Math.sqrt(Math.pow(end_pt.x - start_pt.x, 2) + Math.pow(end_pt.y - start_pt.y, 2))
  const angle = Math.atan2(end_pt.y - start_pt.y, end_pt.x - start_pt.x) * 180 / Math.PI
  return {
    position: 'absolute',
    left: offset.x + start_pt.x,
    top: offset.y + start_pt.y,
    width: length,
    height: 1,
    transform: `rotate(${angle}deg)`,
  } as CSSProperties;
}

const decoCSS = (deco:GlitchDeco, offset:{x:number,y:number}) => {
  return {
    position: 'absolute',
    left: offset.x + (deco.x - deco.w/2),
    top: offset.y + (deco.y - deco.h),
    width: deco.w,
    height: deco.h,
    zIndex: deco.z,
  } as CSSProperties;
}

const createLayerImage:(l:GlitchLayer,newCanvas?:fabric.StaticCanvas, useSvg?:boolean)=>Promise<fabric.StaticCanvas> = async (layer, newCanvas,useSvg=false) => {

  if(!newCanvas){
    const canvas = (typeof document != 'undefined') ? document.createElement('canvas') : null;
    newCanvas = new fabric.StaticCanvas(canvas);
  }

  newCanvas.setWidth(layer.w);
  newCanvas.setHeight(layer.h);

  // TODO declare global configuration object, and get the baseUrl from the config (so that in dev we use dev.viwoc.com)
  // let baseUrl = 'http://127.0.0.1:5050'; 
  // if(typeof document != 'undefined'){
    let baseUrl = process.env.NODE_ENV == 'production' ? 'https://www.viwoc.com' : 'https://dev.viwoc.com';
  // }

  const [offset_x,offset_y] = layer.name == 'middleground' ? [layer.w/2, layer.h] : [0,0];

  for(let item of sortBy(layer.decos,(x)=>x.z)){

    let image:fabric.Object;

    let scaleX, scaleY;
    if(useSvg){
      const url=`${baseUrl}/glitch/assets/${item.filename}.svg?safe=true`;
      if(process.env.NODE_ENV == 'development')
        console.log("Requesting:", url);
      image = await loadFabricSvg(url);
      scaleX= item.w/(image.width || 1);
      scaleY= item.h/(image.height || 1);
    }else{
       image = await loadFabricImage(`${baseUrl}/glitch/assets/${item.filename}.svg?safe=true`);
       const img:HTMLImageElement = (image as fabric.Image).getElement() as HTMLImageElement;
       scaleX= item.w/img.naturalWidth;
       scaleY= item.h/img.naturalHeight;
    }

    image.set({
      item: item,
      originX: 'center',
      originY: 'bottom',
      scaleX,
      scaleY,
      left: item.x + offset_x,
      top:  item.y + offset_y,
      angle: item.r,
      flipX: item.h_flip
    } as any);
    newCanvas.add(image)
    newCanvas.calcOffset();
  }

  return newCanvas;
}

const createLayerImage2:(id:string, l:GlitchLayer,x:(a:string)=>void)=>Promise<string> = (layerId, layer, err_cb) => {

  const newCanvas = document.createElement('canvas');
  newCanvas.width= layer.w;
  newCanvas.height= layer.h;

  const [offset_x,offset_y] = layerId == 'middleground' ? [layer.w/2, layer.h] : [0,0];
  const ctx:(CanvasRenderingContext2D | null) = newCanvas.getContext('2d');

  if(!ctx)
    throw "Error: could not get 2d cntext from canvas"

  return loadLayer(layer,ctx, err_cb,offset_x,offset_y).then(()=> {
    return newCanvas.toDataURL();
  })
}

const createLayerImages:(game:GlitchGame)=>Promise<{[name:string]:string}> = async (game_json) => {
  const width= game_json.dynamic.r - game_json.dynamic.l;
  const height= game_json.dynamic.b - game_json.dynamic.t;

  let canvas;
  if(typeof document != 'undefined'){
    canvas = document.createElement('canvas');
  }else{
    canvas = null;
  }
  const k = new fabric.StaticCanvas(canvas);
  const backgrounds:{[name:string]:string}={};

  for(let id in game_json.dynamic.layers){
    k.clear();
    const layer = game_json.dynamic.layers[id];
    await createLayerImage(layer,k)
    backgrounds[layer.name] = k.toDataURL();
  }

  return backgrounds;

}

const createCompatibleLayer:(id:string,l:GlitchLayer,url?:string)=>Promise<LayerAttributes> = async (layerId,layer,url) => {

  let src;
  if(url){
    src=url;
  }else{
    const canvas = document.createElement('canvas');
    const newCanvas = new fabric.StaticCanvas(canvas, {enableRetinaScaling: false});

    await createLayerImage(layer,newCanvas);

    src = newCanvas.toDataURL();
  }

  const retval = {
    id: layerId,
    z: layer.z,
    offset: layer.offset,
    filters: layer.filters,

    tile_width: layer.w,
    tile_height: layer.h,
    width: 1,
    height: 1,
    total_width: layer.w,
    total_height: layer.h,

    tiles: [{  name: layerId,
      x: 0, 
      y: 0, 
      events: {},
      id: layerId,
      space_id: 'asdf',
      public_filename: src,
    }]
  };

  return retval;

}

const loadLayer = (layer:GlitchLayer, targetContext:CanvasRenderingContext2D, err_cb:(a:string)=>void, offset_x:number, offset_y:number) => {

  return sortBy(layer.decos,(x)=>x.z).reduce((p, item) => {

    return p.then(() => loadImage(item).then((image)=>{
      const drawnItem = drawItem(item, image)
      targetContext.drawImage(drawnItem, item.x - (drawnItem.width / 2) + offset_x, item.y - (drawnItem.height / 2) + offset_y, drawnItem.width, drawnItem.height);
    }, (error)=>{
      err_cb(item.filename)
    }));

  }, Promise.resolve())
}

const drawItem:(deco:GlitchDeco,img:HTMLImageElement)=>HTMLCanvasElement = (item:GlitchDeco, newImg:HTMLImageElement) => {

  const sourceCanvas = $('<canvas>').attr({width: item.w, height: item.h * 2}) as JQuery<HTMLCanvasElement>;
  const sourceContext = sourceCanvas[0].getContext('2d');

  if(!sourceContext)
    throw "No 2d context could be get from canvas"

  if (item.h_flip) {
    sourceContext.scale(-1, 1);
    sourceContext.drawImage(newImg, -item.w, 0, item.w, item.h);
  } else {
    sourceContext.drawImage(newImg, 0, 0, item.w, item.h);
  }

  if (!('r' in item))
    return sourceCanvas[0];

  /* it has rotation */
  const maxEdgeSize = Math.sqrt(Math.pow(item.h * 2, 2) + Math.pow(item.w, 2));

  let theCanvas = $('<canvas>').attr({width: maxEdgeSize, height: maxEdgeSize}) as JQuery<HTMLCanvasElement>;
  const ctx = theCanvas[0].getContext('2d');

  if(!ctx || !theCanvas)
    throw "No 2d context could be get from canvas"

  ctx.translate((theCanvas.width() || 0) / 2, (theCanvas.height() || 0) / 2);
  ctx.rotate(Math.PI/180 * item.r);
  ctx.drawImage(sourceCanvas[0], -item.w / 2, -item.h, item.w, item.h * 2);

  return theCanvas[0];
}

const loadImage:(d:GlitchDeco)=>Promise<HTMLImageElement> = (item) => {
  // TODO declare global configuration object, and get the baseUrl from the config (so that in dev we use dev.viwoc.com)
  const baseUrl = typeof document != 'undefined' ? '' : 'https://www.viwoc.com'

  return new Promise((accept,reject) => {
    var newImg = $('<img>').css({
      'position': 'absolute',
      'width': item.w,
      'height': item.h,
      'left': 0,
      'top': 0
    }).attr('crossorigin', 'Anonymous') as JQuery<HTMLImageElement>;

    newImg.bind('error', reject)
    newImg.bind('load', ()=>accept(newImg[0]))
    $('.offscreenBuffer').append(newImg);
    newImg.attr('src', `${baseUrl}/glitch/assets/${item.filename}.svg?safe=true`);
  })
}

const loadFabricSvg:(src:string)=>Promise<fabric.Object> = (src) => new Promise((accept,reject) => {
  fabric.loadSVGFromURL(src, (objects:any, options, /*, error:boolean*/)=>{
    if(!objects){
      reject();
      return;
    }
    const error=false;
    if(error){
      reject();
      return;
    }
    let obj:any=fabric.util.groupSVGElements(objects, options);
    obj.naturalWidth = options.width;
    obj.naturalHeight = options.height;
    accept(obj);
  })
})

const loadFabricImage:(src:string)=>Promise<fabric.Image> = (src) => new Promise((accept,reject) => {
  fabric.Image.fromURL(src, (image:any/*, error:boolean*/)=>{
    const error=false;
    if(error)
      reject();
    else
      accept(image);
  }, {crossOrigin: 'anonymous'})
})

export {parseGradients, parseFilters, drawItem, loadImage, loadFabricImage, loadLayer, createLayerImage, createCompatibleLayer, parseGlitchLocation, createSpaceFromLocation, stageCSS, decoTransform, decoCSS, createLayerImages, wallCSS, platformLineCSS, loadFabricSvg}
