import GamePreview from './preview';
import React from 'react';
import { ListPagination } from 'components/shared/list_pagination';
import { Games } from 'api/agent';
import { withRouter } from 'react-router-dom'
import { store } from 'redux/store'
import { GameAttributes } from 'models/game';
import { connect } from 'react-redux';
import { UserAttributes } from 'models/user';

class GameList extends React.Component<{limit?:number, games:Array<any>, history:any, currentUser:UserAttributes},{showOnlyMine:boolean, my_games:GameAttributes[], games:GameAttributes[]}> {

  async componentWillMount() {

    const res = await Games.all()
    this.setState({games: res.slice(0, this.props.limit || res.length)})

    const mine = await Games.all({mine:true, q: '*'})
    this.setState({my_games: mine.slice(0, this.props.limit || res.length)})

  }

  set showOnlyMine(mine) {
    if(mine == this.state.showOnlyMine)
      return;

    this.setState({...this.state, showOnlyMine: mine})
  }

  state = {
    my_games: [],
    games: [],
    showOnlyMine: false,
  }

  render(){

    if (!this.state.games.length) {
      return (<div className="article-preview">Loading...</div>);
    }

    if (this.state.games.length === 0) {
      return (
        <div className="article-preview">
          No games are here... yet.
        </div>
      );
    }

    return (
        <div className="row mt-4">
          <div className="col-12">
            {this.props.currentUser && 
            <ul className="nav nav-pills">
              <li className="nav-item">
                <a className={`nav-link ${this.state.showOnlyMine ? 'inactive' : 'active'}`} aria-current="page" onClick={(e)=>this.showOnlyMine=false}>All Games</a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${this.state.showOnlyMine ? 'active' : 'inactive'}`} onClick={(e)=>this.showOnlyMine=true}>My Games</a>
              </li>
            </ul>
          }

          </div>
          {(this.state.showOnlyMine ? this.state.my_games : this.state.games).map(game => {
              return (
                <div className="col-12 col-md-3"  key={`container-${game.uuid}`} >
                  <GamePreview game={game} currentUser={this.props.currentUser} onClick={()=>this.props.history.push(`/game_welcome/${game.uuid}`)}/>
                </div>
                );
            }) }

          {this.props.currentUser && 
            <div className="col-12 col-md-3">
              <div className="btn btn-primary btn-xl" onClick={()=>this.props.history.push(`/games/new`)}>Create Game</div>
            </div>
          }

          <ListPagination {...this.props}/>
        </div>
    );
  }

}

const mapStateToProps = ({common}, props) => ({
  ...props,
  currentUser: common.currentUser,
});

export default connect(mapStateToProps)(withRouter(GameList));
