import React, {Suspense, lazy} from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { APP_LOAD, REDIRECT } from 'constants/actionTypes';
import Landing1 from '../components/Home/landing1';
import { push } from 'react-router-redux';
import { Auth} from 'api/agent';
import { store } from 'redux/store';
import Header from './shared/Header';
import Login from './account/Login';
import Register from './account/Register';
import GameWelcome from './game_welcome';
import { AvatarIndex } from './avatars';
import { ArtworkIndex } from './artwork';
import { NpcIndex } from './npcs';
import { ClazzIndex } from './clazzes';
import { SoundIndex } from './sounds';
import { PictureIndex } from './pictures';
import { VideoIndex } from './videos';
import { TextIndex } from './texts';
import TextNew from './texts/new';

import { WorldMap } from './world/map';
import ScriptList from './scripts/list';
import SpaceList from './spaces/list';
import SpaceNew from './spaces/new';
import WebResourceList from './web_resources/list';
import WebResourceNew from './web_resources/new';
import WebResourceEdit from './web_resources/edit';
import WebResourceShow from './web_resources/show';
import GameList from './games/list';
import GameShow from './games/show';
import GameNew from './games/new';
import { ScriptShow } from './scripts/show';
import { GlitchSpace } from './glitch/locations/space'
import ErrorBoundary from './ErrorBoundary';
import { GlitchHtml } from './glitch/html_space';
import { FabricGlitch } from './glitch/fabric';
import { GlitchEditor } from './glitch/editor';
import GameEdit from './games/edit';
import ClazzNew from './clazzes/new';
import BrowserVoiceRecorder from './recorder';
import {GlitchEditorSingle} from './glitch/editor_single';
import {GlitchEditorPower} from './glitch/editor_power';

//const EditorComponent = lazy(() => import('./editor/EditorComponent2'));
const CreatorComponent = lazy(() => import('./creator/Creator'));

function withDiv(WrappedComponent, classNames) {
  return class extends React.Component {
    constructor(props){
      super(props);
    }

    render(){
      return (<>
        <Header></Header>
        <div className={classNames}>
          <WrappedComponent {...this.props}/>
        </div> 
      </>)
    }
  }
}

function withHeader(WrappedComponent) {
  return class extends React.Component {
    constructor(props){
      super(props);
    }

    render(){
      return (<>
        <Header></Header>
        <WrappedComponent {...this.props}/>
      </>)
    }
  }
}


function withErrorBoundary(WrappedComponent) {
  return class extends React.Component {
    constructor(props){
      super(props);
    }

    render(){
      return (<ErrorBoundary>
        <WrappedComponent {...this.props}/>
      </ErrorBoundary>)
    }
  }
}


/* begin
 * This is for WorldMap */
window.Backbone = require('backbone');
window._ = require('underscore');

//require('../../../vendor/assets/javascripts/backbone.rel');
require('../../../vendor/assets/javascripts/backbone-forms/backbone-forms');
require('../../../vendor/assets/javascripts/backbone-forms/adapters/backbone.bootstrap-modal');
require('../../../vendor/assets/javascripts/backbone-forms/editors/list');
require('backbone-forms/backbone-forms-html');
require('backbone-forms/backbone-forms-file');
require('backbone-forms/backbone-forms-color');
require('backbone-forms/backbone-forms-number');
require('backbone-forms/backbone-forms-json');
/* end */

const mapStateToProps = state => {
  return {
    appLoaded: state.common.appLoaded,
    appName: state.common.appName,
    currentUser: state.common.currentUser,
    redirectTo: state.common.redirectTo
  }};

const mapDispatchToProps = dispatch => ({

  onLoad: (payload) =>
    dispatch({ type: APP_LOAD, payload, skipTracking: true }),

  onRedirect: () =>
    dispatch({ type: REDIRECT })
});

class Router extends React.Component<{currentUser:any, appName: string, onRedirect:any, onLoad:any, appLoaded:any},{}> {

  componentWillReceiveProps(nextProps) {
    if (nextProps.redirectTo) {
      // this.context.router.replace(nextProps.redirectTo);
      let x:any=push(nextProps.redirectTo);
      store.dispatch(x as never);
      this.props.onRedirect();
    }
  }

  componentDidMount() {
    this.props.onLoad(Auth.current());
  }

  render() {
    if (this.props.appLoaded) {
      return (
        <div className="router">
          <Suspense fallback={<div>Loading...</div>}>

            <Switch>
              <Route exact path="/" component={withHeader(Landing1)}/>

              <Route exact path="/recorder" component={withErrorBoundary(BrowserVoiceRecorder)}/>

              <Route exact path="/avatars" component={withErrorBoundary(withDiv(AvatarIndex, 'container'))}/>
              <Route path="/avatars/:p" component={withErrorBoundary(withDiv(AvatarIndex, 'container'))}/>

              <Route exact path="/npcs" component={withErrorBoundary(withDiv(NpcIndex, 'container'))}/>
              <Route path="/npcs/:p" component={withErrorBoundary(withDiv(NpcIndex, 'container'))}/>

              <Route exact path="/clazzes" component={withErrorBoundary(withDiv(ClazzIndex, 'container'))}/>
              <Route exact path="/clazzes/new" component={withErrorBoundary(withDiv(ClazzNew, 'container'))}/>
              <Route path="/clazzes/:p" component={withErrorBoundary(withDiv(ClazzIndex, 'container'))}/>

              <Route exact path="/sounds" component={withErrorBoundary(withDiv(SoundIndex, 'container'))}/>
              <Route exact path="/pictures" component={withErrorBoundary(withDiv(PictureIndex, 'container'))}/>
              <Route exact path="/videos" component={withErrorBoundary(withDiv(VideoIndex, 'container'))}/>

              <Route exact path="/scripts" component={withErrorBoundary(withDiv(ScriptList, 'container'))}/>

              <Route exact path="/glitch" component={withErrorBoundary(GlitchSpace)}/>
              <Route exact path="/glitch/:id" component={withErrorBoundary(GlitchSpace)}/>
              <Route exact path="/glitch/edit/:id" component={withErrorBoundary(withDiv(GlitchEditor, 'glitch-edit'))}/>
              <Route exact path="/glitch/single/:id" component={withErrorBoundary(withDiv(GlitchEditorSingle, 'glitch-edit'))}/>
              <Route exact path="/glitch/power/:id" component={withErrorBoundary(withDiv(GlitchEditorPower, 'glitch-edit'))}/>
              <Route exact path="/glitch/html/:id" component={withErrorBoundary(GlitchHtml)}/>
              <Route exact path="/glitch/fabric/:id" component={withErrorBoundary(FabricGlitch)}/>

              <Route path="/scripts/:uuid" component={withErrorBoundary(withDiv(ScriptShow, 'container'))}/>

              <Route exact path="/texts" component={withErrorBoundary(withDiv(TextIndex, 'container'))}/>
              <Route exact path="/texts/new" component={withErrorBoundary(withDiv(TextNew, 'container'))}/>

              <Route exact path="/artwork" component={withErrorBoundary(withDiv(ArtworkIndex, 'container'))}/>

              {/* Space Editor 
              <Route exact path="/editor" component={withErrorBoundary(EditorComponent)}/>
              <Route path="/editor/:uuid" component={withErrorBoundary(EditorComponent)}/>
                */}

              {/* Sprite/NPC/Avatar Creator */}
              <Route path="/creator" component={withErrorBoundary(withDiv(CreatorComponent, 'container'))}/>

              <Route exact path="/games" component={withErrorBoundary(withDiv(GameList, 'container'))}/>
              <Route exact path="/games/new" component={withErrorBoundary(withDiv(GameNew, 'container'))}/>
              <Route exact path="/games/:uuid/edit" component={withErrorBoundary(withDiv(GameEdit, 'container'))}/>

              <Route exact path="/web_resources" component={withErrorBoundary(withDiv(WebResourceList, 'container'))}/>
              <Route exact path="/web_resources/new" component={withErrorBoundary(withDiv(WebResourceNew, 'container'))}/>
              <Route exact path="/web_resources/:uuid/edit" component={withErrorBoundary(withDiv(WebResourceEdit, 'container'))}/>
              <Route exact path="/web_resources/:uuid" component={withErrorBoundary(withDiv(WebResourceShow, 'container'))}/>

              <Route path="/games/:uuid/spaces/new" component={withErrorBoundary(withDiv(SpaceNew, 'container'))}/>
              <Route exact path="/games/:game_id/scripts" component={withErrorBoundary(withDiv(ScriptList, 'container'))}/>
              <Route path="/games/:uuid" component={withErrorBoundary(withDiv(GameShow, 'container'))}/>

              <Route exact path="/spaces" component={withErrorBoundary(withDiv(SpaceList, 'container'))}/>

              <Route path="/spaces/:uuid/:x,:y" component={({match}) => (<WorldMap key={match.params.uuid} match={match}/>)}/>
              <Route path="/spaces/:uuid" component={({match}) => (<WorldMap key={match.params.uuid} match={match}/>)}/>

              <Route path="/game_welcome/:uuid/:step" component={GameWelcome}/>
              <Route path="/game_welcome/:uuid" component={GameWelcome}/>

              {/*<Route path="/test/RDE" component={RDEApp}/>*/}

              <Route path="/login" component={withErrorBoundary(withDiv(Login, 'container'))} />
              <Route path="/register" component={withErrorBoundary(withDiv(Register, 'container'))} />
              <Route component={withErrorBoundary(Landing1)}/>
            </Switch>
          </Suspense>
        </div>
      );
    }else{
      return (
        <div>
          <Header/>
        </div>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Router);
