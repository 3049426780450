import React from 'react';
import {Texts} from 'api/agent';
import { ListPagination } from 'components/shared/list_pagination';
import ListErrors from 'components/shared/ListErrors';
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";

type TextNewState = {
  name:string,
  description:string,
  content:string,
};

class TextNew extends React.Component<{errors:any[]},TextNewState> {

  static defaultProps = {
  }

  componentWillMount() {
  }

  state = {
    name:"",
    description:"",
    content:"",
  }

  render(){

    return (
      <div className="row">
        <div className="col-md-6 offset-md-3 col-xs-12">
          <h1 className="text-xs-center">New Text</h1>

          <ListErrors errors={this.props.errors} />
      
          <form className="form-horizontal" method="post" onSubmit={ev => {ev.preventDefault();this.submitForm()}}>
            <div className="form-group">
              <label className="control-label col-12 col-md-4" htmlFor="space_Name">Name:</label>
              <div className="col-md-8">
                <input className="form-control" type="text" value={this.state.name} name="space[name]" onChange={(e) => this.setState({...this.state, name:e.target.value})} />
              </div>
            </div>

            <div className="form-group">
              <label className="control-label col-12 col-md-4" htmlFor="space_description">Description:</label>
              <div className="col-md-8">
                <textarea className="form-control" value={this.state.description} name="space[description]" onChange={(e) => this.setState({...this.state, description:e.target.value})}></textarea>
              </div>
            </div>

            <div className="form-group">
              <label className="control-label col-12 col-md-4" htmlFor="space_description">Description:</label>
              <div className="col-md-8">
                <ReactTrixRTEToolbar toolbarId="react-trix-rte-editor" />
                <ReactTrixRTEInput
                  toolbarId="react-trix-rte-editor"
                  defaultValue="React Trix Rich Text Editor"
                  onChange={(e, newValue) => this.setState({...this.state, content: newValue})}
                  />
              </div>
            </div>


            <div className="form-group">
              <div className="col-md-8 col-md-offset-4">
                <input type="submit" name="commit" value="Save" className="btn btn-primary" data-disable-with="Save"/>
              </div>
            </div>

          </form>
        </div>
      </div>
    );
  }


  submitForm(): void {
    const text = {
      name: this.state.name,
      description: this.state.description,
      content: this.state.content,
    }
    Texts.create(text);
  }

}

export default TextNew;

