
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { GAME_WELCOME_STEP, MAP_SET_SPACE, MAP_SET_INVENTORY, MAP_SET_AVATAR  } from 'constants/actionTypes';
import './style.scss'

import React  from 'react';
import { Games, Avatars, Npcs, Spaces, Items } from 'api/agent';
import { GameAttributes } from 'models/game';
import { AvatarAttributes } from 'models/avatar';
import { ScrollBox } from 'components/shared/scroll-box';
import { NpcAttributes } from 'models/npc';
import Intro from "./intro";
import Avatar from "./avatar";
import HowTo from "./how_to";
import Interact from "./interact";
import GameBackdrop from "components/shared/backdrop";
import { SpaceAttributes } from "models/space";
import { WorldMap } from "components/world/map";
import { store } from 'redux/store';
import { CircularProgress } from "@material-ui/core";
import { createSpaceFromLocation, parseGlitchLocation } from "components/glitch/utils";

class Welcome extends React.Component<{currentStep:number,handleNext:Function, match:any, avatarSelected:(avatar)=>void, space?:SpaceAttributes}, {game:GameAttributes, avatars:AvatarAttributes[], current:string, npc: NpcAttributes, name: string, loading:boolean}> {

  scrollBox: ScrollBox;

  constructor(props){
    super(props)
    this.state={
      loading:true,
      game:null,
      name: "Guest Player",
      avatars:null,
      current:null,
      npc:null,
    }
  }

  componentWillUnmount() {
    this.props.handleNext(0);
  }

  componentWillMount() {
    this.props.handleNext(0);
  }

  async componentDidMount() {
    this.props.handleNext(0);
    let game;
    if(this.state.game == null || this.state.game.uuid != this.props.match.params.uuid){
      game = await Games.show(this.props.match.params.uuid);
      this.setState({...this.state, game});
    }

    if(this.state.avatars == null){
      const avatars = await Avatars.all({bag:'nice-avatars'});
      this.setState({...this.state, avatars})
    }

    if(this.state.npc == null){
      const npc = await Npcs.sample();
      this.setState({...this.state, npc})
    }

    if(this.props.match.params.step){
      this.setState({...this.state, current: this.state.avatars[0].id});
      this.props.handleNext(parseInt(this.props.match.params.step))
    }

    if(game){

      let space_json = await Spaces.show(game.start_space)

      if(space_json.location){
        const layers = await parseGlitchLocation(space_json.location.metadata);
        space_json = createSpaceFromLocation(layers, space_json)
      }

      this.setState({...this.state, loading: false});
      store.dispatch({ type: MAP_SET_SPACE, space: space_json, game});
      store.dispatch({ type: MAP_SET_INVENTORY, payload: Items.user_inventory()});
    }
  }

  setName(name){
    this.setState({...this.state, name: name});
  }

  get name(){
    return this.state.name || "Guest Player 04"
  }

  get sampleNpc(){
    if(!this.state.npc)
      return null;
    const sprite = this.state.npc.sprites.filter(sp => sp.map?.sequences)[0];
    return sprite;
  }

  get selectedSprite(){
    if(!this.state.avatars || !this.state.current)
      return null;
    const ava = this.state.avatars.find((a) => a.id == this.state.current)
    const sprite = ava.sprites.filter(sp => sp.map?.sequences)[0];
    return sprite;
  }

  avatarClick(id:string){
    this.props.avatarSelected(this.state.avatars.find((x)=>x.id==id));
    this.setState({...this.state,current: id});
  }

  handleScroll(left:number){
    const idx = Math.floor(left / $(this.scrollBox.scrollingBox).find('img:first').width()) % this.state.avatars.length;
    const fr = this.state.avatars[idx].id
    this.setState({...this.state, current: fr});
  }

  render() {

    if (this.props.currentStep == 4 && this.props.space) {

      if(this.props.space){
        return <WorldMap key={this.props.space.uuid}></WorldMap>
      }else{
        return <Redirect to={`/spaces/${this.state.game.start_space}`} ></Redirect>
      }
    }

    return (
      <div>
	{this.state.game && <div className="welcome-bg" style={{backgroundImage: `url(${this.state.game.loading_preview_url})`, position: 'fixed', width: '100%', height: '100%'}}>
	  <GameBackdrop>
	  {this.props.currentStep == 0 && <Intro game={this.state.game} handleNext={this.props.handleNext}/>}
	  {this.props.currentStep == 1 && <Avatar name={this.name} setName={this.setName.bind(this)} game={this.state.game} avatars={this.state.avatars} current={this.state.current}  avatarClick={this.avatarClick.bind(this)} selectedSprite={this.selectedSprite} handleNext={this.props.handleNext} setScrollBox={(i) => this.scrollBox=i}/>}
	  {this.props.currentStep == 2 && <HowTo selectedSprite={this.selectedSprite} handleNext={this.props.handleNext}/>}
	  {this.props.currentStep == 3 && <Interact sampleNpc={this.sampleNpc} selectedSprite={this.selectedSprite} handleNext={this.props.handleNext}/>}

	</GameBackdrop>

        {this.state.loading && 
        <div style={{position: 'fixed', bottom: '5%', width: '100%'}}>
          <h1 className="text-center">Loading...</h1>
          <CircularProgress className="d-block mx-auto" size={80}></CircularProgress>
        </div>
        }

    </div>}

    </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.game_welcome,
  space: state.map.space,
});

const mapDispatchToProps = dispatch => ({
  avatarSelected: (ava) => dispatch({  type: MAP_SET_AVATAR, payload: ava }), 
  handleNext: (n) => dispatch({  type: GAME_WELCOME_STEP, step: n }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Welcome)
