import { BaseModel } from "./base_model";

type PictureAttrs = {
  id:string,
  name:string,
  description:string,
  image_file_name:string,
  css:any,
  image_url: string,
  game_id: string,
}

class Picture extends BaseModel {

  constructor(attributes, options) {
    super(attributes, options);
    this.dispatchCreated('Picture');
  }

  protectedAttributes() {
    return super.protectedAttributes().concat(["image_file_name", "image_url"]);
  }

  _className() {
    return "Picture";
  }

  initialize(attributes, options) {
    return super.initialize(attributes, options);
  }

  url = () =>{
    return [(window as any).Niconitos.config.api, "pictures", this.id || ''].join('/');
  }

};

Picture.prototype.schema = {
  name: "Text",
  description: "Text",
  temp_file_key: "Filepicker"
};

export { Picture};
