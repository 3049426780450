import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import axios from 'axios';
import { MapController } from 'maps/controller';
import { install_event_handlers } from 'maps/events';
import { Space, SpaceAttributes, LayerAttributes } from 'models/space';
import { Avatar, AvatarAttributes } from 'models/avatar';
import { User } from 'models/user';
import { PersonaView } from 'maps/views/persona_view';
import { Events } from 'maps/map_events';
import { CircularProgress, FormControlLabel, Switch } from '@material-ui/core';
import WebResourceShow from '../web_resources/show';

import 'stylesheets/map.scss' 
import 'stylesheets/modal-fullscreen.scss' 

import { MAP_SET_SPACE, MAP_SET_ZOOM, MAP_SET_INVENTORY, MAP_SHOW_WEB_RESOURCE, MAP_EDIT_SCRIPT, MAP_EDIT_NPC_PLACEMENT , MAP_EDIT_ANCHOR } from 'constants/actionTypes';
import { Drawers } from './drawers';
import { Modal } from '@material-ui/core';
import SpaceConfig from 'components/spaces/configuration';
import { Spaces, Anchors, Items, Games } from 'api/agent';
import { SpaceOverview } from 'components/spaces/overview';
import { AnchorAttributes, Anchor } from 'models/anchor';
import { store , history } from 'redux/store';
import BrandNav from './brand_nav';
import { MapMenu } from './map_menu';
import { MapView } from 'maps/views/map_view';
import { parseGlitchLocation, createSpaceFromLocation } from 'components/glitch/utils';
import { WebResourceAttributes } from 'models/web_resource';
import WebResourcePreview from 'components/web_resources/preview';
import WebResourceView from 'components/web_resources/view';
import { GameAttributes, Game } from 'models/game';
import { LoadedContent } from './loaded_content';
import { ScriptAttributes, Script } from 'models/script';
import EditScript from 'components/scripts/edit_script';
import { ScriptExecutor } from 'models/script_executor';
import { NpcPlacement } from 'models/npc_placement';
import EditNpcPlacement from 'components/npcs/edit_npc_placement';
  import EditAnchor from 'components/anchors/edit_anchor';
require('jquery-ui-touch-punch')

type MapState = {
  loading:boolean,
  open_people:boolean,
  open_drawer:boolean,
  open_overview:boolean,
  open_config:boolean,
  controller: MapController,
};

type MapProps = {
  match:any,
  anchors:AnchorAttributes[],
  people:{[uuid:string]:AvatarAttributes},
  currentUser: any /*TODO type user*/,
  avatar: AvatarAttributes,
  edit_script: Script,
  player: ScriptExecutor,
  edit_npc_placement: NpcPlacement,
  edit_anchor: Anchor,
  space: SpaceAttributes,
  game: GameAttributes,
  show_web_resource: WebResourceAttributes,
  closeWebResource: ()=>void,
  onCloseWebResource: ()=>void,
  closeEditScript: ()=>void,
  closeEditNpcPlacement: ()=>void,
  closeEditAnchor: ()=>void,
}

class WorldMapComponent extends React.Component<MapProps,MapState> {

  map_view: MapView;
  uniqKey: string;

  constructor(props){
    super(props);
    this.uniqKey = _.uniqueId();
    console.log(`Created new WorldMap: ${this.uniqKey}`,this);
  }

  root: HTMLDivElement;

  state = {
    loading: true,
    open_drawer: false,
    open_overview: false,
    open_config: false,
    open_people: false,
    controller: null,
  }

  unlisten: any;

  async componentDidMount () {

    setTimeout(()=>{
      const h = $(window).height() - $('#header').height();
      $(this.root).css({height: h});
    },1000)

    const controller= new MapController({el: this.root});

    const ava = this.props.avatar;
    const usr = this.props.currentUser; //(window as any).current_user;
    const avatar = new Avatar(ava,{dispatcher:controller});
    const user = new User(usr || {},{dispatcher:controller});

    (window as any).app=controller;
    install_event_handlers(controller, false);

    window.senyor = new PersonaView({ user, model : avatar,dispatcher:controller,isLocal:true});

    let space_json:SpaceAttributes;
    let game:GameAttributes=null;
    let uuid = this.props.match?.params?.uuid;

    if(this.props.space){
      uuid = this.props.space.uuid;
      console.log(`yeah ${this.uniqKey}`);
      space_json = this.props.space;
    }else if(uuid){
      if(uuid.length < 36){
        const game_json = await $.get('/locations/' + uuid + '.json')
        const layers = await parseGlitchLocation(game_json);
        space_json = createSpaceFromLocation(layers, {});
        space_json.uuid=uuid;
      }else{
        space_json = await Spaces.show(this.props.match?.params?.uuid)
        if(space_json.location){
          const layers = await parseGlitchLocation(space_json.location.metadata,space_json.location.layer_images_map);
          space_json = createSpaceFromLocation(layers, space_json)
        }
        game = await Games.show(space_json.game_id);
      }
      store.dispatch({ type: MAP_SET_SPACE, space: space_json, game});
    }else{
      space_json = (window as any).space;
    }

    const space = new Space({...space_json, id: space_json.uuid},{dispatcher:controller})

    let x=200, y=space.height - 200;

    if(this.props.match?.params?.x){
      x=parseFloat(this.props.match?.params?.x)
      y=parseFloat(this.props.match?.params?.y)
    } else if(space_json.metadata?.initial_point){
      try{
        const anchor = await Anchors.show(space_json.metadata.initial_point);
        x=anchor.x;
        y=anchor.y;
      }catch(e){
        console.log('Initial point not found');
      }
    }

    //controller.trigger(Events.SET_SPACE,space, {x,y});
    controller.space = space;
    this.map_view = new MapView({ model: space, dispatcher: controller });

    this.root.appendChild(this.map_view.el);
    controller.map = this.map_view;
    this.map_view.init_tiles();
    this.map_view.reset_npcs();
    this.map_view.paint_anchors();

    controller.trigger(Events.SET_LOCAL_AVATARVIEW, {
      x, y,
      avatar: window.senyor
    });


    if(space_json.metadata?.initial_zoom){
      setTimeout(()=>{
        store.dispatch({type: MAP_SET_ZOOM, zoom: space_json.metadata?.initial_zoom})
      },1500);
    }

    if(space_json.metadata?.background_music){
      controller.trigger(Events.PLAY_SOUND, {id: space_json.metadata.background_music, thing: controller.map})
    }

    // Listen for changes to the current location.
    //this.unlisten = history.listen(async (location, action) => {
    //  // location is an object like window.location
    //  //console.log(action, location.pathname, location.state);
    //  const splat = location.pathname.split('/');

    //  if(splat[1] != 'spaces') return;
    //  if(action != 'POP') return;

    //  const olduuid=store.getState().map?.space?.uuid;
    //  const uuid = splat[2];
    //  if(olduuid == uuid) return;

    //  let [x,y]=[100,100];

    //  if(splat.length > 3)
    //    [x,y] = splat[3].split(',').map(parseFloat)

    //  const space_json = await Spaces.show(uuid)
    //  const space = new Space({...space_json, id: space_json.uuid},{dispatcher:controller})

    //  controller.trigger(Events.SET_SPACE, space, {x,y})
    //});

    console.log(`set lading to false ${this.uniqKey}`);
    this.setState({...this.state,controller, loading: false})

  }

  componentWillUnmount () {
    console.log(`unmounting ${this.uniqKey}`);
    this.state.controller?.leaveSpace();
    this.map_view?.remove();

    if(this.unlisten)
      this.unlisten();

    store.dispatch({type: MAP_SET_SPACE, space: null});
  }

  render(){
    console.log(`rendered map component ${this.uniqKey}`)
    return (
      <>
    <div id="holding">
      <Drawers
        open_drawer={this.state.open_drawer}
        open_people={this.state.open_people}
        controller={this.state.controller}
        canEdit={this.props.space?.canEdit}
        hasPeople={!!Object.keys(this.props.people ||{}).length}
        setOpenDrawer={(v)=>this.setState({...this.state,open_drawer: v})}
        setOpenPeople={(v)=>this.setState({...this.state,open_people: v})}
        people={this.props.people}
      >
      </Drawers>

      <Modal
        open={Boolean(this.props.show_web_resource)}
        onClose={(e) => {this.props.onCloseWebResource && this.props.onCloseWebResource(); this.props.closeWebResource()}}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1020}}
        container={() => document.getElementById('holding')}
        disableAutoFocus={true}
        disableEnforceFocus={true}
      >
        <div style={{ width: 800, backgroundColor: 'white', border: '2px solid #000', boxShadow: '2px 2px 2px black', padding: '1rem',margin: '5rem auto', top: '3rem'}}>
          <WebResourceView web_resource={this.props.show_web_resource}></WebResourceView>
        </div>
      </Modal>

      <Modal
        open={this.state.open_config}
        onClose={(e) => this.setState({...this.state, open_config: false})}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1020}}
        container={() => document.getElementById('holding')}
        disableAutoFocus={true}
        disableEnforceFocus={true}
      >
        <div style={{ width: 800, backgroundColor: 'white', border: '2px solid #000', boxShadow: '2px 2px 2px black', padding: '1rem',margin: '5rem auto', top: '3rem'}}>
          <SpaceConfig errors={[]} controller={this.state.controller}></SpaceConfig>
        </div>
      </Modal>

      <Modal
        open={this.state.open_overview}
        onClose={(e) => this.setState({...this.state, open_overview: false})}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1020}}
        container={() => document.getElementById('holding')}
        disableAutoFocus={true}
        disableEnforceFocus={true}
      >
        <div style={{ width: 800, backgroundColor: 'white', border: '2px solid #000', boxShadow: '2px 2px 2px black', padding: '1rem',margin: '5rem auto', top: '3rem'}}>
          <SpaceOverview errors={[]} controller={this.state.controller} space={this.props.space} onClose={()=>this.setState({...this.state,open_overview:false})}></SpaceOverview>
        </div>
      </Modal>

      <div id="holder" className="map" ref={(e) => this.root = e} style={{width:'100%', position: 'relative', backgroundColor: 'rgb(229, 227, 223)'}}>
        {this.state.loading && 
        <div className="row" style={{marginTop: '6rem'}}>
          <div className="col-12 col-md-6 offset-md-3">
            <h1 className="text-center">Loading...</h1>
            <CircularProgress className="d-block mx-auto mt-4" size={80}></CircularProgress>
          </div>
        </div>
        }

    {this.props.edit_anchor && <EditAnchor anchor={this.props.edit_anchor} controller={this.state.controller} onClose={this.props.closeEditAnchor}></EditAnchor>}

    {this.props.edit_npc_placement && <EditNpcPlacement npc_placement={this.props.edit_npc_placement} controller={this.state.controller} onClose={this.props.closeEditNpcPlacement}></EditNpcPlacement>}

    {this.props.edit_script && <EditScript player={this.props.player} script={this.props.edit_script} controller={this.state.controller} onClose={this.props.closeEditScript}></EditScript>}

    {(!this.state.loading) && this.state.controller &&
    <LoadedContent controller={this.state.controller}
      space={this.props.space}
      game={this.props.game}
      setOpenOverview={(v) => this.setState({...this.state, open_overview: v})}
      setOpenConfig={  (v) => this.setState({...this.state, open_config: v})}
      setOpenDrawer={  (v) => this.setState({...this.state,open_drawer: v})}
      setOpenPeople={  (v) => this.setState({...this.state,open_people: v})}></LoadedContent>}
  </div>
      </div>
    </>
    )
  }

}

const mapStateToProps = ({map, common}, ownProps) => { 
  ownProps.space = ownProps.space || map.space;
  return ({
    ...ownProps, 
    edit_anchor: map.edit_anchor,
    edit_npc_placement: map.edit_npc_placement,
    edit_script: map.edit_script,
    player: map.player,
    show_web_resource: map.show_web_resource,
    people: map.people,
    avatar: map.avatar,
    game: map.game,
    inventory: map.inventory,
    currentUser: common.currentUser,
    onCloseWebResource: map.closeWebResource,
  })
}

const mapDispatchToProps = (dispatch) => ({

  closeWebResource: () => dispatch({ type: MAP_SHOW_WEB_RESOURCE, web_resource: null }),

  closeEditScript: () => dispatch({ type: MAP_EDIT_SCRIPT, edit_script: null, player: null}),

  closeEditNpcPlacement: () => dispatch({ type: MAP_EDIT_NPC_PLACEMENT, edit_npc_placement: null}),

  closeEditAnchor: () => dispatch({ type: MAP_EDIT_ANCHOR, anchor: null}),

});


const WorldMap = connect(mapStateToProps,mapDispatchToProps)(WorldMapComponent)

export {WorldMap }
