import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper, { PaperProps } from '@material-ui/core/Paper';

function TransparentPaperComponent(props: PaperProps) {
  return (
    <Paper {...props} style={{background: 'transparent', boxShadow: 'none'}}/>
  );
}

export default function Intro({game, handleNext}) {
  return <Dialog
    disableBackdropClick
    disableEscapeKeyDown
    className="game_welcome intro"
    open={true}
    onClose={()=>handleNext(1)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth="md"
    fullWidth={true}
    PaperComponent={TransparentPaperComponent}
  >
  <DialogTitle id="alert-dialog-title-2" disableTypography={true}>
  </DialogTitle>
  <DialogContent>
      <div className="row">
        <div className="col-6 offset-3 col-md-3 offset-md-1">
          <img className="img-fluid" src={game.logo_url}></img>
        </div>
        <div className="col-12 col-md-6 offset-md-1">
          <h1>{game.name}</h1>
          <p>{game.description}</p>
          <div className="btn btn-white" onClick={()=>handleNext(1)}>
            Play
          </div>
        </div>
      </div>
  </DialogContent>

</Dialog>
}
