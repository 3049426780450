import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { lila_oscuro } from 'stylesheets/styles';
import './backdrop.scss'

// Can't make useStyles to work, and already lost some hours. TODO
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: lila_oscuro,
    },
  }),
);

export default function GameBackdrop(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
	{props.children}
      </Backdrop>
  );
}

