import React, { useState, useRef, useEffect } from 'react';
import { MapController } from 'maps/controller';
import { NpcPlacement, IEventable, InteractionEvent } from 'models/npc_placement';
import { store } from 'redux/store';
import { MAP_EDIT_NPC_PLACEMENT, MAP_EDIT_SCRIPT } from 'constants/actionTypes';
import { v4 as uuidv4 } from 'uuid';
import { Sprite } from 'models/sprite';
import ScriptPreview from 'components/scripts/preview';
import { ScriptAttributes, Script, ScriptTarget } from 'models/script';
import { SoundPreview, SoundProps } from 'components/sounds/sound';
import { Video } from 'components/videos/video';
import { Picture } from 'components/pictures/picture';
import { Text } from 'components/texts/text';
import { Anchor } from 'components/anchors/anchor';
import * as Agent from 'api/agent';
import { Picker } from 'views/picker';
import { ModalView } from 'views/modal_view';
import { ScriptExecutor } from 'models/script_executor';
import { NpcView } from 'maps/views/npc_view';
import { Events } from 'maps/map_events';
import { sweet_prompt, sweeter_prompt } from 'utils/prompt';
import { drawer_width } from 'components/create_toolbox/create_toolbox_drawer';

function RenderEventObject(props:{controller:MapController, onChange:(args:any[])=>void, onPreviewClicked:(kind:string,atts:any)=>void, event:{event:string,command:string,args:any[]}}){

  const [atts, setAtts] = useState(null);
  const onPreviewClicked = props.onPreviewClicked || console.log;

  const onChange = (args:{id:string, [other:string]:any}|null) => {
    setAtts(args);

    if(args)
      props.onChange([args.id]);
  }

  useEffect(()=>{
    if(!props.event.args.length){
      setAtts(null)
      return;
    }
    switch(props.event.command){
      case 'play_script':
      Agent.Scripts.show(props.event.args[0]).then(onChange);
        return;
      case 'play_sound':
      Agent.Sounds.show((props.event.args[0])).then(onChange);
        return;
      case 'play_video':
      Agent.Videos.show((props.event.args[0])).then(onChange);
        return;
      case 'show_picture':
      Agent.Pictures.show((props.event.args[0])).then(onChange);
        return;
      case 'show_text':
      Agent.Texts.show((props.event.args[0])).then(onChange);
        return;
      case 'travelToAnchor':
      Agent.Anchors.show((props.event.args[0])).then(onChange);
        return;
    }
    return undefined;
  },[props.event.command, props.event.args[0]])

  if(atts){
    switch(props.event.command){
      case 'play_script':
      return <ScriptPreview onClick={()=> onPreviewClicked('Script', atts)} script={atts as ScriptAttributes}></ScriptPreview>
      case 'play_sound':
      return <SoundPreview onClick={()=> onPreviewClicked('Sound', atts)}  sound={atts as SoundProps}></SoundPreview>
      case 'play_video':
      return <Video onClick={()=> onPreviewClicked('Video', atts)}  video={atts}></Video>
      case 'show_picture':
      return <Picture onClick={()=> onPreviewClicked('Picture', atts)}  picture={atts}></Picture>
      case 'show_text':
      return <Text onClick={()=> onPreviewClicked('Text', atts)}  text={atts}></Text>
      case 'travelToAnchor':
      return <Anchor onClick={()=> onPreviewClicked('Anchor', atts)}  anchor={atts}></Anchor>
      default:
      return null;
    }
  }

  switch(props.event.command){
    case 'play_script':
    return  <>
    <PickButton type='Script' onSelected={({id})=>Agent.Scripts.show(id).then(onChange)}></PickButton>
    <CreateButton controller={props.controller} type='Script' onSelected={({id})=>Agent.Scripts.show(id).then(onChange)}></CreateButton>
  </>;

  case 'play_sound':
  return  <>
  <PickButton type='Sound' onSelected={({id})=>Agent.Sounds.show(id).then(onChange)}></PickButton>
  <CreateButton controller={props.controller} type='Sound' onSelected={({id})=>Agent.Sounds.show(id).then(onChange)}></CreateButton>
</>;

case 'play_video':
return <>
<PickButton type='Video' onSelected={({id})=>Agent.Videos.show(id).then(onChange)}></PickButton>
<CreateButton controller={props.controller} type='Video' onSelected={({id})=>Agent.Videos.show(id).then(onChange)}></CreateButton>
  </>;

  case 'show_picture':
  return <>
  <PickButton type='Picture' onSelected={({id})=>Agent.Pictures.show(id).then(onChange)}></PickButton>
  <CreateButton controller={props.controller} type='Picture' onSelected={({id})=>Agent.Pictures.show(id).then(onChange)}></CreateButton>
</>

case 'show_text':
return <>
<PickButton type='Text' onSelected={({id})=>Agent.Texts.show(id).then(onChange)}></PickButton>
<CreateButton controller={props.controller} type='Text' onSelected={({id})=>Agent.Texts.show(id).then(onChange)}></CreateButton>
  </>

  case 'travelToAnchor':
  return <>
  <PickButton type='Anchor' onSelected={({id})=>Agent.Anchors.show(id).then(onChange)}></PickButton>
  <CreateButton controller={props.controller} type='Anchor' onSelected={({id})=>Agent.Anchors.show(id).then(onChange)}></CreateButton>
</>

default:
return null;
  }
}

function CreateButton(props) {

  if(props.type == 'Script'){
    return <div className="btn btn-outline-white" onClick={async (e)=> Agent.Scripts.create({name: await sweeter_prompt('Name?')}).then(props.onSelected)}>Create Script</div>
  }else{
    return <div className="btn btn-outline-white" onClick={(e)=> props.controller.trigger(Events.NEW_MODEL, { class: props.type, callback: props.onSelected })}>Create {props.type}</div>
  }
}

function PickButton(props) {

  function select(model, container='.map'){
    const p = new Picker({ models: [model], });

    const modal = new ModalView({ view: p, title: `Pick ${model}` });

    modal.$el.css({zIndex: 2005});

    (modal.render().$el.appendTo(container) as any).modal('show');

    return p.promise
  }

  return <div className="btn btn-outline-white" onClick={(e)=> select(props.type).then(props.onSelected)}>Pick {props.type}</div>
}

type EventableProps = {
  getScriptTarget:()=>any,
  controller:MapController,
  eventable:IEventable,
  event:{event:string,command:string, args:any[], id: string},
  onRemove:(event:InteractionEvent)=>void,
  onChange:(event:InteractionEvent)=>void,
  children?:any[]
}

function EventableEvent(props:EventableProps) {
  const [event, setEvent] = useState(props.event);
  const setEvent2 = (ev) => {
    setEvent(ev);
    props.onChange(ev)
  }

  function onPreviewClicked(kind,atts){
    if(kind == 'Script'){
      const where:ScriptTarget = props.getScriptTarget(); //$(`#npc_placement-${props.npc_placement.id}`).data('placement');
      const s= new Script(atts, {dispatcher: props.controller});
      const script = s//.relativize(props.npc_placement.x, props.npc_placement.y);
      const player = new ScriptExecutor({ model: script, where }, {dispatcher: props.controller});
      store.dispatch({ type: MAP_EDIT_SCRIPT, script, player, where});
    }
  }

  return <div>
    <div className="input-group">
      <div className="input-group-prepend">
        <button className="btn btn-outline-white" onClick={(e)=>props.onRemove(props.event)}>
          <i className="fa fa-times-circle"></i>
        </button>
      </div>
      <div className="input-group-append">
        <label className="input-group-text">On</label>
      </div>
      <select className="custom-select" id="event-select" onChange={(e) => setEvent2({...event, event:e.target.value})} value={event.event}>
        {(props.eventable instanceof NpcPlacement) && <>
        <option value="click">Click</option>
        <option value="hover">Hover</option>
      </>}
        <option value="repeat">Repeat</option>
        <option value="near">Near</option>
      </select>
      <div className="input-group-append">
        <label className="input-group-text">Then</label>
      </div>

      {event.event == 'repeat' && (props.eventable instanceof NpcPlacement) ?
      <select className="custom-select" id="anim-select" value={event.command} onChange={(e) => setEvent2({...event, command:e.target.value})}>
        {(props.eventable as NpcPlacement).npc.sprites.map((sprite:Sprite) => Object.keys(sprite.map.sequences)).flat(1).map((anim) => {
          return <option value={anim}>{anim}</option>
        })}
      </select>
        :
        <select className="custom-select" id="command-select" value={event.command} onChange={(e) => setEvent2({...event, command: e.target.value})}>
          <option value="play_script">Play Script</option>
        {(props.eventable instanceof NpcPlacement) && <>
          <option value="play_video">Play Video</option>
          <option value="play_sound">Play Sound</option>
          <option value="show_picture">Show Picture</option>
          <option value="show_text">Show Text</option>
          <option value="travelToAnchor">Travel to</option>
        </>}
        </select>
      }

    </div>

    {event.event == 'repeat' && (props.eventable instanceof NpcPlacement) ? null : <RenderEventObject controller={props.controller} onPreviewClicked={onPreviewClicked} onChange={(args)=>event.args=args} event={event}></RenderEventObject>}
  </div>
}

export {RenderEventObject, EventableEvent}
