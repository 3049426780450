import React from 'react';

class ErrorBoundary extends React.Component<{},{hasError:boolean}> {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 offset-md-3">
            <div className="alert alert-danger mt-4">
              Something went wrong.
            </div>
            <p>
              Try to <a href="javascript:history.back()">go back</a> or send us an e-mail to <a href="mailto:info@viwoc.com">info@viwoc.com</a>.
            </p>
          </div>
        </div>
      </div>;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
