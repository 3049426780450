import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link, IconButton } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function SpacePreview(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root + String(props.className || '')}>
      <CardActionArea onClick={(e) => props.onClick(props.space)}>
        <CardMedia
          className={classes.media}
          image={props.space.preview_url}
          title={props.space.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.space.name}
          </Typography>

          <Typography variant="body2" color="textSecondary" component="p">
            {props.space.description}
          </Typography>


          {props.showUsers && <Typography>{props.space.current_user_count} users</Typography>}

        </CardContent>
      </CardActionArea>

      {(!props.hideActions) &&
        <CardActions>
          {props.space.canEdit && 
          <>
            {props.space.location &&
            <a className="btn btn-outline-warning" href={`/#/glitch/edit/${props.space.uuid}`}>
              Edit
            </a>
            }
          </>}
            <a className="btn btn-outline-primary" href={`/#${props.url}`}>
              Visit
            </a>

          </CardActions>
      }
    </Card>
  );
}
