import {
  APP_LOAD,
  REDIRECT,
  LOGOUT,
  LOGIN,
  REGISTER,
  HOME_PAGE_UNLOADED,
  LOGIN_PAGE_UNLOADED,
} from 'constants/actionTypes';

const defaultState = {
  appName: 'Viwoc',
  csrf_token: null,
  viewChangeCounter: 0
};

export default (state = defaultState, action) => {

  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        csrf_token: action.csrf_token || null,
        appLoaded: true,
        currentUser: action.payload ? action.payload.user : null
      };

    case REDIRECT:
      return { ...state, redirectTo: null };

    case LOGOUT:
      return { ...state, redirectTo: '/', csrf_token: null, currentUser: null };

    case LOGIN:
    case REGISTER:
      return {
        ...state,
        csrf_token: action.payload.csrf_token,
        redirectTo: action.error ? null : '/',
        currentUser: action.error ? null : action.payload.user
      };

    case HOME_PAGE_UNLOADED:
    case LOGIN_PAGE_UNLOADED:
      return { ...state, viewChangeCounter: state.viewChangeCounter + 1 };

    default:
      return state;
  }
};

