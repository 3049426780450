import React, { MouseEventHandler } from "react";
import Card  from 'react-bootstrap/Card';
import { SpriteProps } from "components/sprites/sprite";
import { SpriteCard } from "components/sprites/sprite_card";
import { AvatarAttributes } from "models/avatar";

const AvatarCard: React.FC<AvatarAttributes & {onClick: MouseEventHandler<HTMLDivElement>}> = (props) => { 

  return (<Card>
    <Card.Header>
      <strong>{props.name}</strong>
    </Card.Header>

    <Card.Body onClick={props.onClick}>

      {props.sprites.filter(sp => sp.map?.sequences).map((sprite) =>
        <SpriteCard sprite={sprite} html={{className:"card-img"}} key={sprite.id}></SpriteCard>
      )}

      <Card.Text>{props.description}</Card.Text>
    </Card.Body>

  </Card>)
}

export {AvatarCard }
