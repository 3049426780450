import React from 'react';
import { connect } from 'react-redux';
import GameList from '../games/list';
import {
  HOME_PAGE_LOADED,
  HOME_PAGE_UNLOADED,
} from 'constants/actionTypes';

const Promise = window.Promise;

const mapStateToProps = state => ({
  ...state.home,
  appName: state.common.appName,
});

const mapDispatchToProps = dispatch => ({
  onLoad: (tab, pager, payload) =>
    dispatch({ type: HOME_PAGE_LOADED, tab, pager, payload }),
  onUnload: () =>
    dispatch({  type: HOME_PAGE_UNLOADED })
});

class Home extends React.Component {

  props: any;

  componentWillMount() {
    const tab = 'all';
    this.props.onLoad(tab);
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    return (
      <div className="home-page">

        <div className="container page">
          <div className="row mt-4">
            <div className="col-12 col-md-12">
              <h1>Bienvenido a Viwoc</h1>
              <h2>Elige un juego</h2>
            </div>
          </div>
          <GameList/>
        </div>

      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
