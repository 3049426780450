import { Redirect } from "react-router-dom";
import React from 'react';
import { connect } from 'react-redux';
import {Spaces, Sounds, Anchors} from 'api/agent';
import {DirectUploadProvider} from 'react-activestorage-provider';

import * as ActiveStorage from 'activestorage';
import { pick_model } from "components/shared/pick_model";
import { Space, SpaceAttributes } from "models/space";
import { MapController } from "maps/controller";

type SpaceConfigState = {
  name:string,
  sound: any,
  anchor: any,
  initial_zoom: number,
};

class SpaceConfigComponent extends React.Component<{errors:any[], controller:MapController, uuid:string, background_music:string, initial_zoom:number,initial_point:string},SpaceConfigState> {

  static defaultProps = {
  }

  async componentWillMount() {

    if(this.props.background_music){
      const sound = await Sounds.show(this.props.background_music)
      this.setState({...this.state, sound})
    }

    if(this.props.initial_point){
      const anchor = await Anchors.show(this.props.initial_point)
      this.setState({...this.state, anchor})
    }

    this.setState({...this.state, initial_zoom: this.props.initial_zoom})

  }

  state = {
    name:"",
    sound:null,
    anchor:null,
    initial_zoom:1,
  }

  render(){
    return (
      <div className="row">
        <div className=" col-12">
          <h4 className="text-xs-center mb-4">Configure Space</h4>

          <form className="form-horizontal mt-4" method="post" onSubmit={ev => {ev.preventDefault();this.submitForm()}}>
            <div className="form-group row">
              <label className="control-label col-12 col-md-4" htmlFor="space_Sound">Background sound:</label>
              <div className="col-md-6">
                <div className="form-control">
                  {this.state.sound?.name}
                  <div className="btn btn-outline-default btn-sm float-right" onClick={e => this.pick_sound()}>Select</div>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="control-label col-12 col-md-4" htmlFor="space_Sound">Initial point:</label>
              <div className="col-md-6">
                <div className="form-control">
                  {this.state.anchor?.name}
                  <div className="btn btn-outline-default btn-sm float-right" onClick={e => this.pick_anchor()}>Select</div>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="control-label col-12 col-md-4" htmlFor="space_Sound">Zoom:</label>
              <div className="col-md-4">
                <input type="number" step={0.1} min={0.1} max={1.5} value={this.state.initial_zoom} className="form-control" onChange={e => this.setState({...this.state,initial_zoom: parseFloat(e.target.value)})}/>
              </div>
            </div>



            <div className="form-group row">
              <div className="col-md-8">
                <input type="submit" name="commit" value="Save" className="btn btn-primary" data-disable-with="Save"/>
              </div>
            </div>

          </form>
        </div>
      </div>
    );
  }

  async pick_anchor(): Promise<any> {
    const anchor = await pick_model('Anchor', {dispatcher: this.props.controller});
    this.setState({...this.state, anchor: anchor.attributes});
  }

  async pick_sound(): Promise<any> {
    const snd = await pick_model('Sound', {dispatcher: this.props.controller});
    this.setState({...this.state, sound: snd.attributes});
  }

  async submitForm(): Promise<any> {
    const space = {
      metadata: {
        background_music: this.state.sound?.uuid,
        initial_point: this.state.anchor?.uuid,
        initial_zoom: this.state.initial_zoom,
      }
    }
    Spaces.update(this.props.uuid, space)
  }

}

const mapStateToProps = (state) => {
  return {
    uuid: state.map.space.uuid, 
    background_music: state.map.space.metadata?.background_music, 
    initial_point: state.map.space.metadata?.initial_point,
    initial_zoom: state.map.space.metadata?.initial_zoom,
  }
};

const mapDispatchToProps = dispatch => ({
  onHola: (hola) => console.log(hola)
  //dispatch({ type: APP_LOAD, payload, skipTracking: true }),
})

const SpaceConfig = connect(mapStateToProps,mapDispatchToProps)(SpaceConfigComponent);

export default SpaceConfig;
