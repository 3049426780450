import WebResourcePreview from './preview';
import React from 'react';
import { ListPagination } from 'components/shared/list_pagination';
import { WebResources } from 'api/agent';
import { withRouter } from 'react-router-dom'
import { store } from 'redux/store'
import { WebResourceAttributes } from 'models/web_resource';
import { connect } from 'react-redux';
import { UserAttributes } from 'models/user';

class WebResourceList extends React.Component<{limit?:number, web_resources:Array<any>, history:any, currentUser:UserAttributes},{showOnlyMine:boolean, my_web_resources:WebResourceAttributes[], web_resources:WebResourceAttributes[]}> {

  async componentWillMount() {

    const res = await WebResources.all()
    this.setState({web_resources: res.slice(0, this.props.limit || res.length)})

    const mine = await WebResources.all({mine:true, q: '*'})
    this.setState({my_web_resources: mine.slice(0, this.props.limit || res.length)})

  }

  set showOnlyMine(mine) {
    if(mine == this.state.showOnlyMine)
      return;

    this.setState({...this.state, showOnlyMine: mine})
  }

  state = {
    my_web_resources: [],
    web_resources: [],
    showOnlyMine: false,
  }

  render(){

    if (!this.state.web_resources.length) {
      return (<div className="article-preview">Loading...</div>);
    }

    if (this.state.web_resources.length === 0) {
      return (
        <div className="article-preview">
          No web_resources are here... yet.
        </div>
      );
    }

    return (
        <div className="row mt-4">
          <div className="col-12">
            {this.props.currentUser && 
            <ul className="nav nav-pills">
              <li className="nav-item">
                <a className={`nav-link ${this.state.showOnlyMine ? 'inactive' : 'active'}`} aria-current="page" onClick={(e)=>this.showOnlyMine=false}>All WebResources</a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${this.state.showOnlyMine ? 'active' : 'inactive'}`} onClick={(e)=>this.showOnlyMine=true}>My WebResources</a>
              </li>
            </ul>
          }

          </div>
          {(this.state.showOnlyMine ? this.state.my_web_resources : this.state.web_resources).map(web_resource => {
              return (
                <div className="col-12 col-md-3"  key={`container-${web_resource.uuid}`} >
                  <WebResourcePreview web_resource={web_resource} currentUser={this.props.currentUser} onClick={()=>this.props.history.push(`/web_resources/${web_resource.uuid}`)}/>
                </div>
                );
            }) }

          {this.props.currentUser && 
            <div className="col-12 col-md-3">
              <div className="btn btn-primary btn-xl" onClick={()=>this.props.history.push(`/web_resources/new`)}>Create WebResource</div>
            </div>
          }

          <ListPagination {...this.props}/>
        </div>
    );
  }

}

const mapStateToProps = ({common}, props) => ({
  ...props,
  currentUser: common.currentUser,
});

export default connect(mapStateToProps)(withRouter(WebResourceList));
