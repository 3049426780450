import React, { Component, CSSProperties } from "react"
import { SpriteFrameType } from "models/sprite";

type SpriteProps = {
  id: string,
  map: {
    frames: {
      [frame_id:string]: SpriteFrameType
    }
    sequences: {
      [sequence:string]: {
        rate: number,
        frames: Array<string>
      }
    }
  },
  public_filename: string,
  width: number,
  height: number,
  sprite_width:number,
  sprite_height:number
}

class Sprite extends Component<{name:string, playing:boolean, sprite:SpriteProps } & React.HTMLAttributes<HTMLDivElement>,{idx:number}> {

  updateTimer:any;

  constructor(props){
    super(props)
    this.state = {
      idx: 0
    }
  }

  getFrameFor(name:string,idx:number){
    return this.props.sprite.map.frames[this.props.sprite.map.sequences[name].frames[idx]]
  }

  get currentFrame():SpriteFrameType {
    if(this.props.name && typeof(this.state.idx) == 'number')
      return this.getFrameFor(this.props.name, this.state.idx)
    else
      return this.getFrameFor(Object.keys(this.props.sprite.map.sequences)[0], 0)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.name !== this.props.name) {
      this.setState({...this.state,idx: 0});
    }
  }

  componentDidMount() {
    this.updateTimer = setInterval(() => this.tick(), 100);
  }

  componentWillUnmount() {
    if (this.updateTimer) {
      clearInterval(this.updateTimer);
    }
  }

  tick() {
    if(!this.props.playing)
      return;
    let newidx = (this.state.idx + 1) % this.props.sprite.map.sequences[this.props.name].frames.length;
    this.setState({...this.state,idx: newidx});
  }

  getInnerStyle():CSSProperties{

    if(this.currentFrame){
      return {
        backgroundColor: 'transparent',
        backgroundImage: 'url(' + this.props.sprite.public_filename + ')',
        backgroundRepeat: 'no-repeat',
        top: this.currentFrame.spriteSourceSize.y,
        left: this.currentFrame.spriteSourceSize.x,
        width: this.currentFrame.frame.w,
        height: this.currentFrame.frame.h,
        backgroundPosition: (-this.currentFrame.frame.x) + "px " + (-this.currentFrame.frame.y) + "px"
      }
    }else{
      return {}
    }
  }

  getOuterStyle():CSSProperties{
    if(this.currentFrame){
      return { }
    }else{
      return {
        width: 100,
        height: 100
      }
    }
  }


  render() {
    return (
      <div className="Sprite">
        <div style={this.getOuterStyle()}>
         <div style={this.getInnerStyle()}>
         </div>
        </div>
      </div>

    )
  }

}
type Sprite2Props = {
  name:string
  playing:boolean
  display_width:number
  sprite:SpriteProps
  onMouseEnter:Function
  onMouseLeave:Function
}

const spriteInnerStyle = (frame:SpriteFrameType, img:HTMLImageElement, display_width:number):CSSProperties => {
  let pixel_ratio =  display_width  / frame.frame.w;
  let x = Math.ceil(frame.frame.x * pixel_ratio);
  let y = Math.ceil(frame.frame.y * pixel_ratio);

  return {
    top: `${-y}px`,
    left: `${-x}px`,
    width: Math.floor(img.naturalWidth * pixel_ratio),
    height: Math.floor(img.naturalHeight * pixel_ratio),
    position: 'absolute',
    transform: frame.transforms ? Object.keys(frame.transforms).reduce((acc, key) => acc + ` ${key}(${frame.transforms[key]})`, '') : '',
  }

}

const spriteOuterStyle = (frame:SpriteFrameType, display_width):CSSProperties => {
  let pixel_ratio =  frame.frame.w  / frame.frame.h;
  return {
    width: display_width,
    height: Math.floor(display_width / pixel_ratio),
    overflow: 'hidden',
    position: 'relative',
  }

}

class Sprite2 extends Component<Sprite2Props,{idx:number, img_loaded:boolean}> {

  updateTimer:any;
  img: HTMLImageElement;

  constructor(props){
    super(props)
    this.state = {
      idx: 0,
      img_loaded:false
    }
  }

  getFrameFor(name:string,idx:number):SpriteFrameType{
    return this.props.sprite.map.frames[this.props.sprite.map.sequences[name].frames[idx]]
  }

  get currentFrame():SpriteFrameType {
    if(this.props.name && typeof(this.state.idx) == 'number')
      return this.getFrameFor(this.props.name, this.state.idx)
    else
      return this.getFrameFor(Object.keys(this.props.sprite.map.sequences)[0], 0)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.name !== this.props.name) {
      this.setState({...this.state,idx: 0});
    }
  }

  componentDidMount() {
    this.updateTimer = setInterval(() => this.tick(), 100);
  }

  componentWillUnmount() {
    if (this.updateTimer) {
      clearInterval(this.updateTimer);
    }
  }

  tick() {
    if(!this.props.playing)
      return;
    let newidx = (this.state.idx + 1) % this.props.sprite.map.sequences[this.props.name].frames.length;
    this.setState({...this.state,idx: newidx});
  }

  get innerStyle():CSSProperties{

    if(this.currentFrame && this.img && this.img.naturalWidth)
      return spriteInnerStyle(this.currentFrame, this.img, this.props.display_width)

    return {
      width: this.props.display_width,
      height: this.props.display_width,
    }
  }

  get outerStyle():CSSProperties{
    if(this.currentFrame)
      return spriteOuterStyle(this.currentFrame, this.props.display_width);

    return {}
  }

  render() {
    return (
      <div className="Sprite"
        style={{width: this.props.display_width}}
        onMouseEnter={(e) => this.props.onMouseEnter && this.props.onMouseEnter(e)}
        onMouseLeave={(e) => this.props.onMouseLeave && this.props.onMouseLeave(e)}>
        <div style={this.outerStyle}>
          <img onLoad={()=>this.setState({...this.state, img_loaded:true})} ref={(node) => this.img = node} src={this.props.sprite.public_filename} style={this.innerStyle}>
         </img>
        </div>
      </div>

    )
  }

}


export { SpriteProps, Sprite, Sprite2, spriteInnerStyle, spriteOuterStyle}
