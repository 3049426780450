import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Auth } from 'api/agent';
import { LOGOUT } from 'constants/actionTypes';
import { store } from 'redux/store';
import owl from 'images/glitch/ilmenskie/owl_1.svg';
import './header.scss';
import { UserAttributes, isAdmin } from 'models/user';

const LoggedOutView = props => {
    return (
      <>
        {props.children}
      </>
    );
};

class HeaderComponent extends React.Component<{appName:string, currentUser?: UserAttributes, onLogout:Function},{}>  {

  get isAdmin():boolean{
    return 
  }
  
  render() {
    return (
      <div className="main-navbar">
        <nav className="navbar  navbar-light navbar-expand-lg" id="header">
          <div className="container">

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#general-navbar"  aria-expanded="false">
              <span className='navbar-toggler-icon'></span>
            </button>

            <Link to="/" className="navbar-brand">
              <img src={owl} style={{maxWidth: '1.5rem'}} className="mx-auto d-inline-block mr-4" id='owl' alt="ViWoC"></img>
              <span className="d-inline-block pl-3">{this.props.appName}</span>
            </Link>

            <div id="general-navbar" className='collapse navbar-collapse in'>
              <ul className="nav navbar-nav pull-xs-right">

                <li className="nav-item">
                  <Link to="/games" className="nav-link"> Games </Link>
                </li>

                {this.props.currentUser ? 
                <>
                  <li className="nav-item">
                    <Link to="/creator/upload" className="nav-link"> Create Sprite </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Assets
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <Link to="/avatars" className="dropdown-item"> Avatars </Link>
                      <Link to="/npcs" className="dropdown-item">Npcs</Link>
                      <Link to="/sounds" className="dropdown-item"> Sounds </Link>
                      <Link to="/pictures" className="dropdown-item"> Pictures </Link>
                      <Link to="/videos" className="dropdown-item"> Videos </Link>
                      <Link to="/texts" className="dropdown-item"> Texts </Link>
                      <Link to="/scripts" className="dropdown-item"> Scripts </Link>
                    </div>
                  </li>

                  {isAdmin(this.props.currentUser) && <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Admin
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a href="/admin" className="dropdown-item"> Admin </a>
                      <a href="/admin/games" className="dropdown-item">Games</a>
                      <a href="/admin/spaces" className="dropdown-item">Spaces</a>
                      <a href="/admin/users" className="dropdown-item">Users</a>
                    </div>
                </li>}

                <li className="nav-item">
                  <a href="#" className="nav-link" onClick={e => this.props.onLogout()}>
                    Sign out
                  </a>
                </li>
              </>

              : 

              <>
                <li className="nav-item">
                  <a href="/users/login" className="nav-link">
                    Sign in
                  </a>
                </li>

                <li className="nav-item">
                  <a href="/users/sign_up" className="nav-link">
                    Sign up
                  </a>
                </li>
              </>
            }

          </ul>

        </div>
      </div>
    </nav>
  </div>
    );
  }

  sign_out(): void {
    Auth.logout();
  }
}

const mapDispatchToProps = dispatch => ({
  onLogout: (a) =>
    dispatch({ type: LOGOUT, payload: Auth.logout() })
})

const mapStateToProps = ({common}) => ({
  appName: common.appName,
  currentUser: common.currentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
