import React from 'react';
import Alert from 'react-bootstrap/Alert';

class ListErrors extends React.Component<any,{showing:boolean}> {
  ts: any;

  constructor(props){
    super(props)
    this.ts = props.timestamp;
    this.state = {showing:true};
  }

  componentWillReceiveProps(props, context){
    this.setState({showing: props.timestamp != this.ts})
    this.ts=props.timestamp;
  }
  

  props: any;

  render() {
    const errors=this.props.errors;

    if (errors) {
      return (
        <div>
          {(this.state.showing && <Alert variant="danger" onClose={() => this.setState({showing: false})} dismissible>
            <Alert.Heading>Errors</Alert.Heading>

            {(errors instanceof Array) && 
            <ul className="error-messages">
              {errors.map((key,i) => <li key={i}>{key}</li>)}
            </ul>}

            {(!(errors instanceof Array)) && 
            <ul className="error-messages">
              {Object.keys(errors).map((key) => <li key={key}>{key} {errors[key]} </li>)}
            </ul>}

          </Alert>
        )}
    </div>
  );
    } else {
      return null;
    }
  }
}

export default ListErrors;

