import React, { Component} from "react";
import Card  from 'react-bootstrap/Card';
import ReactPlayer from 'react-player';
import { VideoAttrs } from "models/video";

class Video extends Component<{video:VideoAttrs , onClick?: Function},{isMouseInside:boolean}> { 

  constructor(props:{video:VideoAttrs , onClick?: Function}) {
    super(props)
    this.state = { isMouseInside: false };
  }

  render() {
    return (<Card>
      <Card.Header>
        <strong>{this.props.video.name}</strong>
      </Card.Header>

      <ReactPlayer url={this.props.video.url} width="100%" height="100%" controls={true}/>

      <Card.Body onClick={(e) =>this.props.onClick && this.props.onClick()}>
        <Card.Text>{this.props.video.description}</Card.Text>
      </Card.Body>

      </Card>)
  }
}
export {Video }
