import SpacePreview from './preview';
import React from 'react';
import { ListPagination } from 'components/shared/list_pagination';
import { Spaces } from 'api/agent';
import { withRouter } from 'react-router-dom'

class SpaceList extends React.Component<{spaces:Array<any>, history:any},{}> {

  componentWillMount() {
    Spaces.list().then((res) => {
      this.setState({spaces:res})
    });
  }

  state = {
    spaces: []
  }

  render(){

    if (!this.state.spaces.length) {
      return (<div className="article-preview">Loading...</div>);
    }

    if (this.state.spaces.length === 0) {
      return (
        <div className="article-preview">
          No spaces are here... yet.
        </div>
      );
    }

    return (
      <div className="row mt-4">
        {
          this.state.spaces.map(space => {
            return (
              <div className="col-12 col-md-3"  key={`container-${space.uuid}`} >
                <SpacePreview space={space} onClick={()=>this.props.history.push(`/spaces/${space.uuid}`)}/>
              </div>
              );
          })
        }

        <ListPagination {...this.props}/>
      </div>
    );
  }

}

export default withRouter(SpaceList);
