import SpacePreview from '../spaces/preview';
import React from 'react';
import { Redirect } from "react-router-dom";
import { ListPagination } from 'components/shared/list_pagination';
import { Spaces, Games } from 'api/agent';
import { withRouter } from 'react-router-dom'
import { GameAttributes } from 'models/game';
import { SpaceAttributes } from 'models/space';
import Link from '@material-ui/core/Link';
import { UserAttributes } from 'models/user';
import { connect } from 'react-redux';

class GameShow extends React.Component<{currentUser:UserAttributes, spaces:Array<SpaceAttributes>, history:any, match: any},{game:GameAttributes, spaces:SpaceAttributes[], destroyed?:boolean, cloned?:GameAttributes}> {

  componentWillMount() {
    Spaces.list(this.props.match.params.uuid).then((res) => {
      this.setState({...this.state, spaces:res})
    });
    Games.show(this.props.match.params.uuid).then((res) => {
      this.setState({...this.state, game:res})
    });

  }

  state = {
    game:null,
    spaces: null,
    destroyed: false,
    cloned: null,
  }

  render(){
    if(this.state.destroyed){
      return <Redirect to={`/games`} />
    }
    if(this.state.cloned){
      return <Redirect to={`/games/${this.state.cloned.uuid}`} />
    }

    if (!this.state.spaces) {
      return (<div className="article-preview">Loading...</div>);
    }


    return (
      <div className="">
        {this.state.game &&
        (<div className="row mt-4">
           <div className="col-12">
             <a className="btn btn-link" href="/#/games"><i className="fa fa-arrow-left"/>Back</a>
           </div>
           <div className="col-12">
             <h1>{this.state.game.name}</h1>
           </div>
           <div className="col-12">
             {this.state.game.canEdit && <a className='btn btn-sm btn-outline-warning' href={`#/games/${this.state.game.uuid}/edit`}>Edit</a>}
             {this.state.game.canEdit && <a className='ml-2 btn btn-sm btn-outline-danger' onClick={(e) => this.destroyGame()}>Destroy</a>}
             {this.props.currentUser &&  <a className='ml-2 btn btn-sm btn-outline-primary' onClick={(e) => this.cloneGame()}>Clone</a>}
             {this.state.game.canEdit && <a className='ml-2 btn btn-sm btn-outline-info' href={`#/games/${this.state.game.uuid}/scripts`}>Scripts</a>}
           </div>
         </div>)
        }
      <div className="row mt-4">
        <div className="col-12 mt-4 mb-4">
          <h3>Espacios que pertenecen a este juego</h3>
        </div>
        {
          this.state.spaces.map((space:SpaceAttributes) => {
            const url = `/spaces/${space.uuid}/${space.start_point.x},${space.start_point.y}`
            return (
              <div className="col-12 col-md-3"  key={`container-${space.uuid}`} >
                <SpacePreview space={space} url={url} onClick={()=>this.props.history.push(url)}/>
              </div>
              );
          })
        }

        {this.state.game?.canEdit && 
          <div className="col-12 col-md-3">
            <div className="btn btn-primary btn-xl" onClick={()=>this.props.history.push(`/games/${this.state.game.uuid}/spaces/new`)}>Add Space</div>
          </div>
        }
        <ListPagination {...this.props}/>
      </div>
      </div>
    );
  }

  async destroyGame() {
    if(!confirm('are you sure you want to destroy this game and all of its spaces?'))
      return;
    await Games.destroy(this.state.game.uuid);
    this.setState({destroyed:true})
  }

  async cloneGame() {
    if(!confirm('are you sure you want to clone this game?'))
      return;
    const cloned = await Games.clone(this.state.game.uuid);
    this.setState({cloned})
  }

}
const mapStateToProps = (state,other) => ({
  ...other, 
  currentUser: state.common.currentUser
})
export default connect(mapStateToProps)(withRouter(GameShow));
