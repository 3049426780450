import { BaseModel } from "./base_model";
import { Sprite, SpriteAttributes } from "./sprite";

type uuid=string;

type NpcAttributes = {
  name: string, 
  x: number, 
  y: number
  id: uuid, 
  sprites: Array<SpriteAttributes>
}

class Npc extends BaseModel {
  name: any;
  avatar_id: string;
  _className() { return 'Npc'; }

  constructor(attributes, options) {
    super(attributes, options);
    this.dispatchCreated('Npc');
  }

  get sprites():Sprite[]{
    return this.attributes.sprites;
  }

  parse(resp, options) {
    const x = super.parse(resp, options);
    if (resp.sprites) {
      x.sprites = resp.sprites.map((s) => new Sprite(s, options));
    }
    return x;
  }

  initialize(settings, opts) {
    this.name = settings["name"];
    this.avatar_id = "Avatar_" + settings["id"];
    super.initialize(settings, opts);
  }

  label() {
    return this.name;
  }

  url = () =>{
    return [(window as any).Niconitos.config.api, "npcs", this.id || ''].join('/');
  }

  protectedAttributes():string[]{
    return super.protectedAttributes().concat(["created_at", "updated_at", "id"])
  }

};


Npc.prototype.schema = {
  name: "Text",
  description: "Text"
};

export { Npc, NpcAttributes};
