import ScriptPreview from './preview';
import React from 'react';
import { ListPagination } from 'components/shared/list_pagination';
import { Scripts } from 'api/agent';
import { withRouter } from 'react-router-dom'
import { ScriptAttributes } from 'models/script';

class ScriptList extends React.Component<{scripts:Array<ScriptAttributes>, match:any, game_id:string, history:any},{}> {

  componentWillMount() {
    if(this.props.match.params.game_id){
      Scripts.all({game_id: this.props.match.params.game_id}).then((res) => {
        this.setState({scripts:res})
      });
    }else{
      Scripts.all().then((res) => {
        this.setState({scripts:res})
      });
    }
  }

  state = {
    scripts: []
  }

  render(){

    if (!this.state.scripts.length) {
      return (<div className="article-preview">Loading...</div>);
    }

    if (this.state.scripts.length === 0) {
      return (
        <div className="article-preview">
          No scripts are here... yet.
        </div>
      );
    }

    return (
      <div className="row mt-4">
        {
          this.state.scripts.map(script => {
            return (
              <div className="col-12 col-md-3"  key={`container-${script.uuid}`} >
                <ScriptPreview script={script} onClick={()=>this.props.history.push(`/scripts/${script.uuid}`)}/>
              </div>
              );
          })
        }

        <ListPagination {...this.props}/>
      </div>
    );
  }

}

export default withRouter(ScriptList);

