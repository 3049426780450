import { combineReducers } from 'redux';
import common from './reducers/common';
import creator from './reducers/creator';
import map from './reducers/map';
import auth from './reducers/auth';
import home from 'components/Home';
import { RouterState, connectRouter  } from 'connected-react-router';
import { GAME_WELCOME_STEP } from 'constants/actionTypes';

export interface State {
  tab: string,
  appName:string,
  viewChangeCounter: number,
  token:string,
  router:RouterState
}

const game_welcome = (state = {currentStep:0}, action) => {
  switch(action.type){
    case GAME_WELCOME_STEP:
      return {currentStep: action.step}
    default:
      return state;
  }
}

// TODO implement type of the state: https://stackoverflow.com/questions/59814381
export default (history: History) => combineReducers({
  game_welcome,
  common,
  creator,
  home,
  map,
  auth,
  router: connectRouter(history)
});

