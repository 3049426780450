import React, {useEffect, useState} from 'react';
import { fabric } from 'fabric';
import { GlitchGame, GlitchLayer, GlitchDeco, GlitchWall } from './space';
import { sortBy} from 'lodash';
import { stageCSS } from './utils';
import { Drawer, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { Spaces, GlitchLocations } from 'api/agent';
import { isAdmin, UserAttributes } from 'models/user';
import { connect } from 'react-redux';
import BlendImage2 from '../../utils/fabric_outline_filter';

const loadFabricImage2:(src:string)=>Promise<fabric.Image> = (src) => new Promise((accept,reject) => {
  fabric.Image.fromURL(src, (image:any/*, error:boolean*/)=>{
    const error=false;
    if(error){
      reject();
      return
    }
    image.needsItsOwnCache=()=>true;
    accept(image);
  }, {crossOrigin: ''})
})


function GlitchEditorSingleComponent(props:{match?:any, currentUser: UserAttributes}) {

  const [gameData, setGameData] = useState<GlitchGame|null>(null);
  const [hidden, setHidden] = useState({})
  const [editing, setEditing] = useState(null);
  const [space_id, setSpaceId] = useState(null);
  const [tsid, setTsid] = useState(null);

  const [layers, setLayers] = useState<{[name:string]: fabric.Canvas}>({});
  let [monolayer, setMonolayer] = useState<fabric.Canvas|null>(null);
  const [canvas, setCanvas] = useState<HTMLCanvasElement|null>(null);
  const [currentScroll, setCurrentScroll] = useState(0);
  const [doParallax, setDoParallax] = useState(false);

  (window as any).layers=layers

  const display = (name) => hidden[name] ? 'none' : 'block';

  const layerStyle = (layer, zRange) => {
    return {
      display: display(layer.name),
      position: 'absolute',
      width: layer.w,
      height: layer.h,
      zIndex: zRange + layer.z,
      pointerEvents: isEditing(layer.name) ? 'auto' : 'none',
      left: 0,
      opacity: !editing ? 1 : (editing == layer.name ? 1 : 0.6),
    } as any;
  }

  const isEditing = (name) => editing == name;

  const sortedLayers:GlitchLayer[] = sortBy(Object.values(gameData?.dynamic?.layers||{}), layer=> layer.z);

  const isHidden = (layer:string):boolean => hidden[layer];

  useEffect(()=> {
    (async () => {
      const uuid = props.match?.params?.id || 'GM410QQ0CHARO';

      let game_json:GlitchGame;
      let tsid:string=null;

      if(uuid.length < 36){
        game_json = await $.get('/locations/' + uuid + '.json')
      }else{
        const space_json = await Spaces.show(uuid)
        if(space_json.location){
          game_json = space_json.location.metadata
          tsid = space_json.location.tsid
        }
      }

      if(game_json){
        setGameData(game_json);
        setTsid(tsid)
        setSpaceId(uuid)
      }
    })()
  },[])


  const serializeItems = ():GlitchGame => {

    const retval:GlitchGame = {...gameData};

    retval.dynamic = {...gameData.dynamic}
    retval.dynamic.layers = Object.keys(layers).reduce((acc,id) => {
      const layer = gameData.dynamic.layers[id];
      const [offset_x,offset_y] = id == 'middleground' ? [layer.w/2, layer.h] : [0,0];
      acc[id] = {...layer};
      acc[id].decos = layers[id].getObjects().map((obj:fabric.Image & {item: GlitchDeco}) => {
        const el:HTMLImageElement = obj.getElement() as HTMLImageElement;
        const x = {...obj.item};
        const j= fabric.util.qrDecompose(obj.calcTransformMatrix())
        x.w = j.scaleX * el.naturalWidth;
        x.h = j.scaleY * el.naturalHeight;
        x.x = obj.left - offset_x;
        x.y = obj.top - offset_y;
        x.r = obj.angle;
        return x;
      });
      return acc;
    }, {})
    return retval;
  }

  const loadLayer = (layer:GlitchLayer, idx:number, canvas:fabric.Canvas):Promise<fabric.Canvas|null> => {
    if(layer.decos.length == 0) return;

    if(layers[layer.name]){
      if(editing != layer.name){
        layers[layer.name].discardActiveObject()
        layers[layer.name].requestRenderAll();
      }
      return;
    }

    layers[layer.name]=canvas;

    return renderLayer(layer,idx, canvas);
  }

  const renderLayer = async (layer:GlitchLayer, idx:number, canvas:fabric.Canvas ) => {
    const width= gameData.dynamic.r - gameData.dynamic.l;
    const height= gameData.dynamic.b - gameData.dynamic.t;

    const layerId=layer.name;

    if(layerId == 'middleground'){
      canvas.setHeight(height);
      canvas.setWidth(width);
    }

    const [offset_x,offset_y] = layerId == 'middleground' ? [layer.w/2, layer.h] : [0,0];

    const middlegroundWidth = gameData.dynamic.layers.middleground.w;
    const layerWidth = layer.w;
    const diff = layerWidth - middlegroundWidth;
    const parallax = diff / middlegroundWidth;

    const decos = sortBy(layer.decos,(x)=>x.z);

    for(let i=0, len=decos.length;i<len;i++){

      const item = decos[i];
      try{
        // const image:fabric.Group = await loadFabricSvg('/glitch/assets/' + item.filename + '.svg') as fabric.Group
        // let scaleX =  item.w / (image as any).naturalWidth;
        // let scaleY =  item.h / (image as any).naturalHeight;

        const image:fabric.Image = await loadFabricImage2('/glitch/assets/' + item.filename + '.svg');
        const img:HTMLImageElement = image.getElement() as HTMLImageElement;
        const scaleX= item.w/img.naturalWidth;
        const scaleY= item.h/img.naturalHeight;

        image.set({
          idx: i,
          parallax,
          layer_idx: idx, 
          z_idx: idx*1000+i,
          offset_x,
          offset_y,
          item,
          originX: 'center',
          originY: 'bottom',
          scaleX,
          scaleY,
          left: item.x + offset_x,
          top:  item.y + offset_y,
          angle: item.r,
          flipX: item.h_flip
        } as any);

        canvas.add(image)
        canvas.calcOffset();

      }catch(e){
        console.error(e)
        continue;
      }

    }

    layer.walls.forEach((wall:GlitchWall,i) => {
      //return <HtmlWall key={`wall-${layer.name}-${i}`} wall={wall} layer={layer} i={i}/>
      const offset = {x: layer.name == 'middleground' ? layer.w/2:0, y: layer.name == 'middleground' ? layer.h : 0 }
      const {x,y,w,h} = wall;
      const l= new fabric.Line([offset.x+x, offset.y+y, offset.x+x+w, offset.y+y+h], {
        stroke: 'red',
        strokeWidth: 2,
        opacity: 1
      })
      canvas.add(l)
    })
    layer.ladders.forEach((ladder,i) => {
      const offset = {x: layer.name == 'middleground' ? layer.w/2:0, y: layer.name == 'middleground' ? layer.h : 0 }
      const {x,y,w,h} = ladder;
      const l= new fabric.Line([offset.x+x, offset.y+y, offset.x+x+w, offset.y+y+h], {
        stroke: 'red',
        strokeWidth: 2,
        opacity: 1
      })
      canvas.add(l)
    })

    layer.platformLines.forEach((pf,i) => {
      //return <HtmlPlatformLine key={`ladders-${layer.name}-${i}`} platformLine={pf} layer={layer} i={i}/>
      const offset = {x: layer.name == 'middleground' ? layer.w/2:0, y: layer.name == 'middleground' ? layer.h : 0 }
      const [start, end] = pf.endpoints.sort((a,b) => { return a.name == 'start' ? -1 : 1});
      const l=new fabric.Line([offset.x+start.x, offset.y+start.y, offset.x+end.x, offset.y+end.y], {
        stroke: 'red',
        strokeWidth: 2,
        opacity: 1
      })
      canvas.add(l)
    })

    var selection = new fabric.ActiveSelection(canvas.getObjects(), { canvas });
    canvas.setActiveObject(selection);   //selecting all objects...
    canvas.requestRenderAll();
    canvas.discardActiveObject();
    return canvas
  }

  const saveGame=(): void =>{
    const newData = serializeItems();
    GlitchLocations.update(space_id, newData);
  }

  const resetLayers=(): void => {
    const newData = serializeItems();
    setGameData(newData);

    Object.keys(layers).forEach((k,i) => {
      const l = layers[k];
      l.clear();
      renderLayer(newData.dynamic.layers[k],i, l);
    })
  }

  const startEditing = async (layerId: string): Promise<void> => {
    if(!layers[layerId]) return;

    if(editing == layerId){
      setEditing(null);
      return;
    }

    setEditing(layerId);

    const layer = layers[layerId];

    var selection = new fabric.ActiveSelection(layer.getObjects(), { canvas:layer });
    layer.setActiveObject(selection);   //selecting all objects...
    layer.requestRenderAll();
    layer.discardActiveObject();
  }

  const updateParallaxOnScroll = (event: React.UIEvent<HTMLDivElement>):any => {
    if(!doParallax)
      return;
    const scroll = event.currentTarget.scrollLeft;
    setCurrentScroll(scroll);

    monolayer.forEachObject((o:any) => {
      if(!o.parallax)return
      o.left = o.item.x + o.offset_x + currentScroll * o.parallax;
    })

    monolayer.requestRenderAll()
  }

  const handleModified = (e) => {
    if(e.target.type != 'image')return;
    //TODO take care of lines etc
    const obj:any = e.target;
    const el:HTMLImageElement = obj.getElement() as HTMLImageElement;
    const {top,left,scaleX,scaleY,angle} =obj;

    const j= fabric.util.qrDecompose(obj.calcTransformMatrix())
    obj.item.w = j.scaleX * el.naturalWidth;
    obj.item.h = j.scaleY * el.naturalHeight;
    obj.item.x = obj.left - obj.offset_x - (doParallax ? obj.parallax*currentScroll : 0);
    obj.item.y = obj.top - obj.offset_y;
    obj.item.r = obj.angle;

    console.log('Modified:',
      (e.target as any).idx,
      (e.target as any).item,
      {top,left,scaleX,scaleY,angle},
      fabric.util.qrDecompose(e.target.calcTransformMatrix()));
  }

  useEffect(() => {
    if(!gameData) return;
    if(!canvas) return;

    if(!monolayer){
      monolayer = new fabric.Canvas(canvas, {enableRetinaScaling: false});
      highlightSelectedObject(monolayer)
      monolayer.preserveObjectStacking=true;
      monolayer.perPixelTargetFind=true;

      monolayer.on('object:modified',handleModified)
      setMonolayer(monolayer)
    }

    Promise.all(sortedLayers.map((layer,i) => loadLayer(layer,i, monolayer) )).then(() => {

      sortBy(monolayer.getObjects(),(o:any) => o.hasOwnProperty('z_idx') ? o.z_idx : 60000).forEach((o,i) => {
        monolayer.moveTo(o, i);
      })

      monolayer.requestRenderAll()
    })

  }, [gameData, canvas])

  if(!gameData)
    return <h1>Loading...</h1>;

  const maxZ = sortedLayers.reduce((max, l) => (l.z > max ? l.z : max), sortedLayers[0].z);
  const minZ = sortedLayers.reduce((min, l) => (l.z < min ? l.z : min), sortedLayers[0].z);
  const zRange = maxZ-minZ;

  return (<div className="glitcher">
    <Drawer
      variant="permanent"
      anchor='left'
      open={true}
      PaperProps={{ style: { position: 'fixed' } }}
      BackdropProps={{ style: { display: 'none' } }}
    >
      <List>
        {sortedLayers.map((layer) => {
          if(!layers[layer.name]) return null;
          //if(layer.name != 'middleground')
          //  return null
          return <ListItem>
            <ListItemIcon onClick={(e) => setHidden({...hidden, [layer.name]: !isHidden(layer.name)})}>
              <i className={`fal ${isHidden(layer.name) ? 'fa-eye-slash' : 'fa-eye'}`}/>
            </ListItemIcon>
            <ListItemIcon onClick={(e) => startEditing(layer.name)}>
              <i className={`fal fa-edit`} style={{opacity: isEditing(layer.name) ? 1 : 0.5}}/>
            </ListItemIcon>
            <ListItemText primary={layer.name} />
          </ListItem>
        })}

  {isAdmin(props.currentUser) && <ListItem>
    <ListItemIcon onClick={(e) => resetLayers()}>
      <i className={`fal fa-play`}/>
    </ListItemIcon>
    <ListItemText primary="Test Serialization" />
</ListItem>}


<ListItem>
  <ListItemIcon onClick={(e) => saveGame()}>
    <i className={`fal fa-save`}/>
  </ListItemIcon>
  <ListItemText primary="Save" />
</ListItem>

</List>
      </Drawer>

      <div className="location_holder" style={{overflow: 'scroll'}} onScroll={(e) => { updateParallaxOnScroll(e); return true}}>
        <div className="location" style={stageCSS(gameData)}>
          <div id={`layer-mono`} style={layerStyle(gameData.dynamic.layers.middleground, zRange)}>
            <canvas ref={(e) => setCanvas(e)} id={`canvas-mono`} style={{width: gameData.dynamic.layers.middleground.w, height: gameData.dynamic.layers.middleground.h}}></canvas>
          </div> 
        </div>

      </div>
    </div>)

}

const highlightSelectedObject = (canvas: fabric.Canvas) => {

  const setFilter = (e: fabric.Object) => {
    if(e.type != 'image')return

    var clonedImage = fabric.util.object.clone(e);
    clonedImage.scale(1.1);

    const f1= [
      new fabric.Image.filters.Blur({blur: 0.5}),
      new fabric.Image.filters.BlendColor({
        color: 'rgb(0,255,0)',
        mode: 'tint'
      }),
      //@ts-ignore
      new BlendImage2({
        image: clonedImage,
        mode: 'visibility',
        alpha: 0.5
      })];
    const imgInstance = e as fabric.Image;
    imgInstance.filters=f1;
    //imgInstance.filters.push(new fabric.Image.filters.Blur());
    //imgInstance.filters.push(new fabric.Image.filters.Grayscale());
    imgInstance.applyFilters();
  }

  const setShadow = (e: fabric.Object) => {
    if(e.type != 'image')return
    var shadow = new fabric.Shadow({
      color: 'rgba(0,255,0,1)',  // Shadow color
      blur: 3,                // How much to blur the shadow
      offsetX: 2,             // Horizontal shadow offset
      offsetY: 2              // Vertical shadow offset
    });
    const imgInstance = e as fabric.Image;
    imgInstance.set('shadow', shadow);
  }

  canvas.on('selection:created', (e) => {
    console.log('Selection Created:',e)
    e.selected.forEach((obj:fabric.Object) => {
      if(obj.type != 'image')return
      setShadow(obj);
      //setFilter(obj)
    })
    canvas.requestRenderAll();
  })

  canvas.on('selection:updated', (e) => {
    console.log('Selection Updated:',e)
    e.selected.forEach((obj:fabric.Object) => {
      if(obj.type != 'image')return;
      setShadow(obj)
      //setFilter(obj)
    })

    e.deselected.forEach((obj:fabric.Object) => {
      if(obj.type != 'image')return
      obj.set('shadow', null);
      //@ts-ignore
      //obj.filters= []
      ////@ts-ignore
      //obj.applyFilters();
    })
    canvas.requestRenderAll()
  })

  canvas.on('selection:cleared', (e) => {
    console.log('Selection Cleared:',e)
    canvas.getObjects().forEach((obj) => {
      if(obj.type != 'image')return
      obj.set('shadow', null);
      //@ts-ignore
      obj.filters= []
      //@ts-ignore
      obj.applyFilters();
    })
    canvas.requestRenderAll();
  })



}


const mapStateToProps = (state,ownProps) => ({
  ...ownProps, 
  currentUser: state.common.currentUser,
});
const GlitchEditorPower= connect(mapStateToProps)(GlitchEditorSingleComponent);

export {GlitchEditorPower}

