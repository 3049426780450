import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import GameList from '../games/list';
import owl from 'images/glitch/ilmenskie/owl_1.svg';
import './landing1.scss';
import 'stylesheets/viwoc-bubble.scss';
import {T} from 'components/utils/t';

import mountains1     from 'images/glitch/mountains1.svg';
import mountains2     from 'images/glitch/mountains2.svg';

import zutto_tree_3     from 'images/glitch/ilmenskie/zutto_tree_3.svg';
import tree_12          from 'images/glitch/ilmenskie/tree_12.svg';
import tree_7           from 'images/glitch/ilmenskie/tree_7.svg';
import tree_8           from 'images/glitch/ilmenskie/tree_8.svg';
import zutto_platform_1 from 'images/glitch/ilmenskie/zutto_platform_1.svg';
import zutto_cloud_1    from 'images/glitch/ilmenskie/zutto_cloud_1.svg';
import zutto_cloud_2    from 'images/glitch/ilmenskie/zutto_cloud_2.svg';
import ground_2         from 'images/glitch/ilmenskie/ground_2.svg';
import ground_3         from 'images/glitch/ilmenskie/ground_3.svg';
import ground_4         from 'images/glitch/ilmenskie/ground_3.svg';

import hill_6         from 'images/glitch/ilmenskie/hill_6.svg';
import hill_7         from 'images/glitch/ilmenskie/hill_7.svg';
import hill_8         from 'images/glitch/ilmenskie/hill_8.svg';
import hill_9         from 'images/glitch/ilmenskie/hill_9.svg';
import hill_10         from 'images/glitch/ilmenskie/hill_10.svg';
import hill_11         from 'images/glitch/ilmenskie/hill_11.svg';
import hill_12         from 'images/glitch/ilmenskie/hill_12.svg';
import hill_13         from 'images/glitch/ilmenskie/hill_13.svg';
import hill_14         from 'images/glitch/ilmenskie/hill_14.svg';

import {
  HOME_PAGE_LOADED,
  HOME_PAGE_UNLOADED,
} from 'constants/actionTypes';

import { AvatarAttributes } from 'models/avatar';
import { Avatars } from 'api/agent';
import { Avatar } from 'components/avatars/avatar';

const prefix = 'js.home.landing1';

const FPS = 10;
const PPS = 500;
const dist = (a,b) => Math.sqrt((b.x-a.x)**2 + (b.y-a.y)**2);
const angle = (a,b) => Math.atan2(b.y-a.y, b.x-a.x)
const norm = (a) => a >= 0 ? a : a+(2*Math.PI)
const direction = (a,b) => ['right','up','left','down'][Math.floor(4*norm(angle(a,b)+Math.PI/4)/(2*Math.PI))];

const mapStateToProps = state => ({
  ...state.home,
  appName: state.common.appName,
});

const mapDispatchToProps = dispatch => ({
  onLoad: () =>
    dispatch({ type: HOME_PAGE_LOADED}),
  onUnload: () =>
    dispatch({  type: HOME_PAGE_UNLOADED })
});

type Point = {
  x:number,y:number
};

type Movement = {
  now:number,
  origin: Point,
  direction: string,
  destination: Point,
  duration:number,
  start:number,
}

type LandingState = {
  avatar:AvatarAttributes,
  movement: Movement,
}

const restrictAvatar = (y, [max,min]) => Math.max(max, Math.min(min, y));

function Landing1B(props) {

  const [state, setState] = useState({
    avatar: null,
    movement: {
      origin: { x: window.innerWidth / 2, y: 513 },
      destination: { x: window.innerWidth / 2, y: 513 },
      direction: 'right',
      now: 1000,
      start: 0,
      duration: 1000,
    },
  });

  const raf = useRef(null);
  const avatarRef = useRef(Avatar);

  // Equivalent to componentWillMount
  useEffect(() => {
    props.onLoad();
    async function fetchAvatars() {
      if (!state.avatar) {
        const avatars = await Avatars.all({ bag: 'home-avatar' });
        const avatar = avatars[0];
        setState((prevState) => ({ ...prevState, avatar }));
      }
    }
    fetchAvatars();

    // Equivalent to componentWillUnmount
    return () => {
      props.onUnload();
      if (raf.current) {
        window.cancelAnimationFrame(raf.current);
      }
    };
  }, []); // Empty array means this effect runs once on mount

  const paint = (now) => {
    const newMov = { ...state.movement, now, start: state.movement.start || now };
    setState((prevState) => ({ ...prevState, movement: newMov }));

    if (!(state.movement.destination.x === x && state.movement.destination.y === y)) {
      raf.current = window.requestAnimationFrame(paint);
    }
  };

  const t = !state.movement.start ? 0 : Math.min(state.movement.now - state.movement.start, state.movement.duration);

  const x = Math.round(state.movement.origin.x + (t * (state.movement.destination.x - state.movement.origin.x) / state.movement.duration));

  const y = restrictAvatar(Math.round(state.movement.origin.y + (t * (state.movement.destination.y - state.movement.origin.y) / state.movement.duration)), [545, 520]);

  const walk = ({ x, y }) => {
    const destination = { x: Math.round(x), y: restrictAvatar(Math.round(y), [545, 520]) };
    const origin = { x, y };
    const dir = direction(origin, destination) || 'right';

    if (origin.x === destination.x && origin.y === destination.y) return;

    setState((prevState) => ({
      ...prevState,
      movement: {
        origin,
        destination,
        direction: ['right', 'left'].indexOf(dir) >= 0 ? dir : 'right',
        duration: dist(origin, destination) * 1000 / PPS,
        now: 0,
        start: null,
      },
    }));

    raf.current = window.requestAnimationFrame(paint);
  };

  const scrollTo = (selector, e) => {
    e?.preventDefault();
    const r = document.querySelectorAll(selector);
    if (r.length) {
      r[0].scrollIntoView();
    }
    return false;
  };

  return (
    <div className="landing1-page">
        <div className="section1" >

          <div className="background" onClick={(e) => walk({x:e.pageX - avatarRef.current.width/2, y:e.pageY - avatarRef.current.height})}>

            {state.avatar && (
              <Avatar fps={FPS} t={t} ref={avatarRef} animation={state.movement.direction} avatar={state.avatar} x={x} y={y}>
                <div className="viwoc-bubble triangle-bottom">
                  <ul className="list-unstyled" style={{marginBottom: 0}}>
                    <li>
                      <a href="#what_is_viwoc" onClick={(e)=>scrollTo('#what_is_viwoc', e)}><i className="fa fa-chevron-right"></i>
                        <T k={`${prefix}.character.what_is_viwoc`}>What is ViWoC ?</T>
                      </a>
                    </li>
                    <li>
                      <a href="#play_game" onClick={(e)=>scrollTo('#play_game', e)}><i className="fa fa-chevron-right"></i>
                        <T k={`${prefix}.character.play_a_game`}>Play a game</T>
                      </a>
                    </li>
                    <li>
                      <a href="/users/sign_up"><i className="fa fa-chevron-right"></i>
                        <T k={`${prefix}.character.sign_up`}>Sign up</T>
                      </a>
                    </li>
                  </ul>
                </div>
              </Avatar>
            )}

            <img className="glitch-asset highlight zutto_tree_3"     src={zutto_tree_3}></img>
            <img className="glitch-asset highlight zutto_tree_3-2"   src={zutto_tree_3}></img>
            <img className="glitch-asset highlight tree_12"          src={tree_12}></img>
            <img className="glitch-asset highlight tree_7"           src={tree_7}></img>
            <img className="glitch-asset highlight tree_8"           src={tree_8}></img>
            <img className="glitch-asset highlight zutto_platform_1" src={zutto_platform_1}></img>
            <img className="glitch-asset highlight zutto_cloud_1"    src={zutto_cloud_1}></img>
            <img className="glitch-asset highlight zutto_cloud_2"    src={zutto_cloud_2}></img>
            <img className="glitch-asset highlight zutto_cloud_1-2"  src={zutto_cloud_1}></img>
            <img className="glitch-asset highlight zutto_cloud_2-2"  src={zutto_cloud_2}></img>
            <img className="glitch-asset ground_2"         src={ground_2}></img>
            <img className="glitch-asset ground_3"         src={ground_3}></img>
            <img className="glitch-asset ground_4"         src={ground_4}></img>
            <img className="glitch-asset ground_2-1"         src={ground_2}></img>
            <img className="glitch-asset ground_3-1"         src={ground_3}></img>
            <img className="glitch-asset ground_4-1"         src={ground_4}></img>
            <img className="glitch-asset ground_2-2"       src={ground_2}></img>
            <img className="glitch-asset ground_3-2"       src={ground_3}></img>
            <img className="glitch-asset ground_4-2"       src={ground_4}></img>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 offset-md-3 heading">
                <img src={owl} style={{maxWidth: '3rem'}} className="mx-auto d-block highlight" id='owl'></img>
                <h1 className="text-center">ViWoC</h1>
                <h2 className="text-center"><T k={`${prefix}.subheader`}>Create games, play with friends, learn new things</T></h2>
                <div className="row mt-4">
                  <div className="col-4 offset-2">
                    <a href="https://www.youtube.com/watch?v=v8lPRLF7Vh4" target="_blank" className="btn btn-info btn-block"><T k={`${prefix}.watch`}>Watch the video</T></a>
                    {/*<a href="https://www.youtube.com/watch?v=wgLWX1mX9dw" target="_blank" className="btn btn-info btn-block">Watch the video</a>*/}
                  </div>
                  <div className="col-4">
                    <a href="/users/sign_up" className="btn btn-primary btn-block"><T k={`${prefix}.sign_up_btn`}>Sign Up</T></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section3"  id="what_is_viwoc">

          <div className="row mt-4">
            <div className="col-12 col-md-8 offset-md-2">
              <div className="container">
                <h2 className="text-center"><T k={`${prefix}.what_is_viwoc_h`}>{`What is <strong>ViWoC ?</strong>`}</T></h2>
                <p className="text-center"><T k={`${prefix}.what_is_viwoc_p`}>Viwoc is a place to make and play games, for kids and educators</T></p>
              </div>
            </div>
          </div>

          <div className="row slide cretae_game">
            <div className="col-12 col-md-4 offset-md-1 text">
              <h3 className=""><T k={`${prefix}.create_your_world_h`}>{`Create your <strong>game world</strong>`}</T></h3>
              <p className=""><T k={`${prefix}.create_your_world_p`}>With super simple creation tools, you can transform your lessons into interactive spaces that host learning and promote collaboration.</T></p>
            </div>
            <div className="col-12 col-md-7 image">
              <img src="/images/landing1/viwoc-tv.png"  className="img-fluid" id='owl'></img>
            </div>

          </div>

          <div className="row slide tell_story">
            <div className="col-12 col-md-4 order-md-1 text">
              <h3 className=""><T k={`${prefix}.tell_a_story_h`}>{`Tell a Story <strong>Make it fun</strong>`}</T></h3>
              <p className=""><T k={`${prefix}.tell_a_story_p`}>Design the interactions that make learning possible. Our straightforward interaction editor will make learning a real trip!</T></p>
            </div>
            <div className="col-12 col-md-7 image">
              <div className="row" style={{position:'relative'}}>
                <img src="https://www.viwoc.com/glitch/assets/flying_platform_2.svg"  className="col-12 col-md-10 offset-md-1" id='owl'></img>
                <img className="highlight" src="https://www.viwoc.com/glitch/assets/npc_garden_gnome.svg" style={{position:'absolute', left: '41%',width:'19%', top: '-29%'}}></img>
                <div className="viwoc-bubble triangle-left gnome-bubble" style={{width: 'auto', left: 'calc(45% + 100px)', bottom: 'auto', top: '-60%', zIndex: 3}}>
                  <div className="row">
                    <div className="col-4">
                      <img src='/images/landing1/gnome-face.jpg' className="avatar-circle img-fluid"></img>
                    </div>
                    <div className="col-8 pl-0">
                      <h4><T k={`${prefix}.gnommie.title`}>Gnommie</T></h4>
                      <p><T k={`${prefix}.gnommie.question`}>Do you want to observe the formation of the Milky Way ?</T></p>
                      <a className="btn-viwoc-link"><T k={`${prefix}.gnommie.btn1`}>Sure, show me how</T></a>
                      <a className="btn-viwoc-link"><T k={`${prefix}.gnommie.btn2`}>Test my knowledge</T></a>
                    </div>
                  </div>
                </div>

                <img className="highlight" src="https://www.viwoc.com/glitch/assets/telescope_01.svg" style={{position:'absolute',  width: '17%', right:'19%',top:'-6%'}}></img>
              </div>
            </div>

          </div>

          <div className="row slide learning_resources">
            <div className="col-12 col-md-5 offset-md-1 text">
              <h3 className=""><T k={`${prefix}.bring_resources.h3`}>{'Bring together all your <strong>learning resources</strong>'}</T></h3>
              <p className="" ><T k={`${prefix}.bring_resources.p` }>{'Text documents, presentations, videos... Incorporate all your learning resources into your ViWoc game.'}</T></p>
            </div>
            <div className="col-12 col-md-6 image" style={{position: 'relative'}}>
              <div className="row" style={{position:'relative'}}>
                <img src="https://www.viwoc.com/glitch/assets/flying_platform_4.svg"  className="col-12 col-md-10 offset-md-1"></img>
                <img className="highlight" src="https://www.viwoc.com/glitch/assets/appleTV.svg" style={{position:'absolute', left: '37%', width: '12%', top:'-30%'}}></img>
                <img className="highlight" src="https://www.viwoc.com/glitch/assets/telescope_01.svg" style={{position:'absolute',  width: '11%', right:'19%',top:'-6%'}}></img>
                <img className="highlight" src="https://www.viwoc.com/glitch/assets/bag_notice_board.svg" style={{position:'absolute', right: '35%', width: '16%', top: '-16%'}}></img>
                <img className="highlight" src="https://www.viwoc.com/glitch/assets/knife_and_board.svg" style={{position:'absolute', left: '15%', top: '-18%', width: '18%'}}></img>
                <img className="highlight" src="https://www.viwoc.com/glitch/assets/dust_trap.svg" style={{position:'absolute', left: '20%', bottom: '66%', width: '17%'}}></img>
              </div>
            </div>
          </div>

          <div className="row slide connected_learning">
            <div className="col-12 col-md-5 order-md-1 text">
              <h3 className=""><T k={`${prefix}.connected_learning.h3`}>{'Connected learning <strong>through collaboration</strong>'}</T></h3>
              <p className="" ><T k={`${prefix}.connected_learning.p` }>{'Set collaborative goals that will spark cooperation.'}</T></p>
            </div>
            <div className="col-12 col-md-6 image">
              <img src="https://www.viwoc.com/glitch/assets/trophy_cubimal.svg"  className="col-12 col-md-8 offset-md-3 highlight"></img>
            </div>
          </div>

          <div className="row slide kids_creators">
            <div className="col-12 col-md-4 offset-md-1 text">
              <h3 className=""><T k={`${prefix}.kids_creators.h`}>{'Kids <strong>become creators</strong>'}</T></h3>
              <p className="" ><T k={`${prefix}.kids_creators.p`}>{'The sleek creation platform allows users of all ages to create their own educational adventures... for school, or just for fun!'}</T></p>
            </div>
            <div className="col-12 col-md-7 image">
              <img src="/images/landing1/viwoc-tv.png"  className="img-fluid"></img>
            </div>
          </div>

          <div className="slide transform_lessons row">
            <div className="col-12 col-md-5 order-md-1 text">
              <h3 className=""><T k={`${prefix}.transform_lessons.h`}>{'Transform Lessons into <strong>Learning Adventures</strong>'}</T></h3>
              <p className=""> <T k={`${prefix}.transform_lessons.p`}>{'Transform your educational materials into adventure games.<br/>Challenge learners to design their own adventures!'}</T></p>
            </div>
            <div className="col-12 col-md-6 image">
              <div className="row" style={{position:'relative'}}>
                <img className="col-12 col-md-10 offset-md-1" src="https://www.viwoc.com/glitch/assets/flying_platform_1.svg"  id='owl'></img>
                <img className="highlight" src="/images/totem-clitch.png"  id='owl' style={{width: '24%', left: '38%', top: '-40%', position: 'absolute'}}></img>
              </div>
            </div>
          </div>

        </div>

        <div className="section2" id="play_game">
          <div className="row hills">
            <div className="col-12">
              <img src={hill_6}  className="highlight hill hill_6"></img>
              <img src={hill_7}  className="highlight hill hill_7"></img>
              <img src={hill_8}  className="highlight hill hill_8"></img>
              <img src={hill_8}  className="highlight hill hill_8-2"></img>
              <img src={hill_9}  className="highlight hill hill_9"></img>
              <img src={hill_10} className="highlight hill hill_10"></img>
              <img src={hill_11} className="highlight hill hill_11"></img>
              <img src={hill_12} className="highlight hill hill_12"></img>
              <img src={hill_13} className="highlight hill hill_13"></img>
              <img src={hill_14} className="highlight hill hill_14"></img>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12 section">
                <h2 className="mt-4 text-center"><T k={`${prefix}.choose_game`}>{'Choose a game'}</T></h2>
                <GameList limit={4}/>
                <h4 className="mt-4 text-center"><a href="/#/games/"><T k={`${prefix}.view_all`}>{'View all games'}</T></a></h4>
              </div>
            </div>
          </div>
        </div>

        <div className="section4">
          <div className="bg">
            <img className="glitch-asset mountains1"     src={mountains1}></img>
            <img className="glitch-asset mountains2"     src={mountains2}></img>
          </div>
        </div>

    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Landing1B);

