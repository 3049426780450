import WebResourcePreview from '../web_resources/preview';
import React from 'react';
import { Redirect } from "react-router-dom";
import { ListPagination } from 'components/shared/list_pagination';
import { WebResources } from 'api/agent';
import { withRouter } from 'react-router-dom'
import { WebResourceAttributes } from 'models/web_resource';
import Link from '@material-ui/core/Link';
import { UserAttributes } from 'models/user';
import { connect } from 'react-redux';

class WebResourceShow extends React.Component<{currentUser:UserAttributes, web_resources:Array<WebResourceAttributes>, history:any, match: any},{web_resource:WebResourceAttributes, web_resources:WebResourceAttributes[], destroyed?:boolean, cloned?:WebResourceAttributes}> {

  componentWillMount() {
    WebResources.list().then((res) => {
      this.setState({...this.state, web_resources:res})
    });
    WebResources.show(this.props.match.params.uuid).then((res) => {
      this.setState({...this.state, web_resource:res})
    });

  }

  state = {
    web_resource:null,
    web_resources: null,
    destroyed: false,
    cloned: null,
  }

  render(){
    if(this.state.destroyed){
      return <Redirect to={`/web_resources`} />
    }
    if(this.state.cloned){
      return <Redirect to={`/web_resources/${this.state.cloned.uuid}`} />
    }

    if (!this.state.web_resources) {
      return (<div className="article-preview">Loading...</div>);
    }


    return (
      <div className="">
        {this.state.web_resource &&
        (<div className="row mt-4">
           <div className="col-12">
             <a className="btn btn-link" href="/#/web_resources"><i className="fa fa-arrow-left"/>Back</a>
           </div>

           <div className="col-12">
             <h2>
               <a href={this.state.web_resource.url} target="_blank">{this.state.web_resource.name}</a>
             </h2>
           </div>

           <div className="col-12">
             <p>{this.state.web_resource.description}</p>
           </div>

           <div className="col-12">
             {this.state.web_resource.canEdit && <a className='btn btn-sm btn-outline-warning' href={`#/web_resources/${this.state.web_resource.uuid}/edit`}>Edit</a>}
             {this.state.web_resource.canEdit && <a className='ml-2 btn btn-sm btn-outline-danger' onClick={(e) => this.destroyWebResource()}>Destroy</a>}
           </div>
         </div>)
        }

        <ListPagination {...this.props}/>
      </div>
    );
  }

  async destroyWebResource() {
    if(!confirm('are you sure you want to destroy this web_resource and all of its spaces?'))
      return;
    await WebResources.destroy(this.state.web_resource.uuid);
    this.setState({destroyed:true})
  }

}
const mapStateToProps = (state,other) => ({
  ...other, 
  currentUser: state.common.currentUser
})
export default connect(mapStateToProps)(withRouter(WebResourceShow));
