import React from 'react';

// k is for "key"
class T extends React.Component<{k:string, nohtml?:boolean},{}> {

  constructor(props){
    super(props);
  }

  render(){
    const opts={_: this.props.children};

    if(!window.polyglot.phrases[this.props.k]){
      window.polyglot.missing = window.polyglot.missing || {};
      window.polyglot.missing[this.props.k] = opts._;
    }

    if(this.props.nohtml)
      return window.polyglot.t(this.props.k,opts)

    return <span className="inibon"  data-inibon-key={`${window.i18n_locale}.${this.props.k}`} dangerouslySetInnerHTML={{__html:window.polyglot.t(this.props.k,opts)}}></span>
  }

}

export { T };
