
const white=  '#fff';
const lila_oscuro= '#541262';
const lila_claro= '#EFBCD5';

const primary_00= '#9C94C7';
const primary_10= '#8379B9';
const primary_20= '#5F54A0';
const primary_40= '#473F78';
const primary_60= '#2E294E';
const primary_80= '#2E294E';

const secondary_00= '#EFBCD5';
const secondary_10= '#E79CC1';
const secondary_20= '#DB6BA3';
const secondary_40= '#CF3A85';
const secondary_60= '#B52C70';
const secondary_80= '#832052';

export {
  white,
  lila_oscuro,
  lila_claro,
  primary_00,
  primary_10,
  primary_20,
  primary_40,
  primary_60,
  primary_80,
  secondary_00,
  secondary_10,
  secondary_20,
  secondary_40,
  secondary_60,
  secondary_80
}
