import { Link } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import {
  UPDATE_FIELD_AUTH,
  REGISTER,
  REGISTER_PAGE_UNLOADED
} from 'constants/actionTypes';

import {Auth} from 'api/agent';
import ListErrors from 'components/shared/ListErrors';

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
  onChangeSurname: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'surname', value }),

  onChangeName: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'name', value }),

  onChangeEmail: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'email', value }),

  onChangePassword: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'password', value }),

  onChangeUsername: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'username', value }),

  onChangeNickname: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'nickname', value }),

  onSubmit: (name, surname, nickname, email, password) => {
    const payload = Auth.register(name, surname, nickname, email, password);
    dispatch({ type: REGISTER, payload })
  },

  onUnload: () =>
    dispatch({ type: REGISTER_PAGE_UNLOADED })
});


class Register extends React.Component {

  changeNickname: (ev: any) => any;
  changeName: (ev: any) => any;
  changeSurname: (ev: any) => any;

  changeEmail: (ev: any) => any;
  changePassword: (ev: any) => any;

  submitForm: (name: any, surname:any, nickname:any, email: any, password: any) => (ev: any) => void;
  props: any;

  constructor(props) {
    super(props);
    this.changeName = ev => this.props.onChangeName(ev.target.value);
    this.changeSurname = ev => this.props.onChangeSurname(ev.target.value);
    this.changeNickname = ev => this.props.onChangeNickname(ev.target.value);

    this.changeEmail = ev => this.props.onChangeEmail(ev.target.value);
    this.changePassword = ev => this.props.onChangePassword(ev.target.value);

    this.submitForm = (name, surname, nickname, email, password) => ev => {
      ev.preventDefault();
      this.props.onSubmit(name, surname, nickname, email, password);
    }
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const name = this.props.name;
    const surname = this.props.surname;
    const nickname = this.props.nickname;
    const email = this.props.email;
    const password = this.props.password;
    const username = this.props.username;

    return (
      <div className="auth-page">
          <div className="row">

            <div className="col-md-6 offset-md-3 col-xs-12">
              <h1 className="text-xs-center">Sign Up</h1>
              <p className="text-xs-center">
                <Link to="/login">
                  Have an account?
                </Link>
              </p>

              <ListErrors errors={this.props.errors}  timestamp={this.props.timestamp}/>

              <form onSubmit={this.submitForm(name, surname, nickname, email, password)}>
                <fieldset>

                  <fieldset className="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="Name"
                      value={this.props.name}
                      onChange={this.changeName} />
                  </fieldset>

                  <fieldset className="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="Surname"
                      value={this.props.surname}
                      onChange={this.changeSurname} />
                  </fieldset>

                  <fieldset className="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="email"
                      placeholder="Email"
                      value={this.props.email}
                      onChange={this.changeEmail} />
                  </fieldset>

                  <fieldset className="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="password"
                      placeholder="Password"
                      value={this.props.password}
                      onChange={this.changePassword} />
                  </fieldset>

                  <fieldset className="form-group">
                    <legend>A visible name for your avatar:</legend>
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="Nickname"
                      value={this.props.nickname}
                      onChange={this.changeNickname} />
                  </fieldset>

                  <button
                    className="btn btn-lg btn-primary pull-xs-right"
                    type="submit"
                    disabled={this.props.inProgress}>
                    Sign up
                  </button>

                </fieldset>
              </form>
            </div>

          </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
