import SpacePreview from './preview';
import { Redirect } from "react-router-dom";
import React from 'react';
import {Spaces} from 'api/agent';
import { ListPagination } from 'components/shared/list_pagination';
import ListErrors from 'components/shared/ListErrors';
import {DirectUploadProvider} from 'react-activestorage-provider';
import { WebResources } from 'api/agent'

import * as ActiveStorage from 'activestorage';
import { WebResourceAttributes } from 'models/web_resource';
import WebResourceForm from './form';

type WebResourceNewState = {
  name:string,
  description:string,
  errors:string[],
  created_web_resource?: WebResourceAttributes,
  creation_status?: string,
};

class WebResourceNew extends React.Component<{errors:any[]},WebResourceNewState> {

  static defaultProps = {
  }

  state:WebResourceNewState = {
    name:"",
    description:"",
    errors:null,
    creation_status: null,
  }

  render(){
    if (this.state.created_web_resource) {
      return <Redirect to={`/web_resources/${this.state.created_web_resource.id}`} />
    }

    return (
      <div className="row">
        <div className="col-md-6 offset-md-3 col-xs-12">
          <h1 className="text-xs-center">New Web Resource</h1>
          <WebResourceForm submitForm={this.submitForm.bind(this)} errors={this.state.errors} creation_status={this.state.creation_status}></WebResourceForm>
        </div>
      </div>
    );
  }

  async submitForm({name, description}): Promise<any> {

    const web_resource = { name, description};

    this.setState({...this.state, creation_status: 'creating'})

    WebResources.create(web_resource).then((e) => {
      this.setState({...this.state, created_web_resource:e, creation_status: null});
    }, (err) => {
      this.setState({...this.state, creation_status: 'error'});
    });
  }

}


export default WebResourceNew;
