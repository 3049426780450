import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link, IconButton } from '@material-ui/core';
  import { GameAttributes } from 'models/game';
  import { UserAttributes } from 'models/user';
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function GamePreview(props:{game:GameAttributes, currentUser?:UserAttributes, onClick:()=>void}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={(e) => props.onClick()}>
        <CardMedia
          className={classes.media}
          image={props.game.preview_url}
          title={props.game.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.game.name}
          </Typography>

          <Typography variant="body2" color="textSecondary" component="p">
            {props.game.description}
          </Typography>
        </CardContent>
      </CardActionArea>

      <CardActions>
        {props.game.canEdit && 
        <>
          <a  className="btn btn-outline-info" href={`/#/games/${props.game.uuid}`}>
            Manage
          </a>
        </>
      }
      {props.game.current_user_count > 0 && <Typography>{props.game.current_user_count} users</Typography>}

      </CardActions>
    </Card>
  );
}
